import React from 'react';
import styles from './social.module.scss';

const Social = () => {
  return (
    <div className={styles.social}>
      <div className={styles.number}>(066) 46-799-95</div>
      <div className={styles.number}>(068) 28-102-14</div>
    </div>
  );
};

export default Social;
