import React from 'react';
import styles from './title.module.scss';

const Title = ({ variant, text, id }) => {
  return (
    <>
      {variant === 'main' ? (
        <h1 className={styles.titleMain}>{text}</h1>
      ) : variant === 'page' ? (
          <h2 className={styles.page}>{text}</h2>
      ) : variant === 'big' ? (
        <h2 className={styles.titleBig}>{text}</h2>
      ) : variant === 'middle' ? (
        <h3 className={styles.titleMiddle}>{text}</h3>
      ) : variant === 'small' ? (
        <h4 className={styles.titleSmall}>{text}</h4>
      ) : variant === 'subtitleMain' ? (
        <h2 className={styles.subtitleMain}>{text}</h2>
      ) : variant === 'subtitleSmall' ? (
        <p className={styles.subtitleSmall}>{text}</p>
      ) : (
        ''
      )}
    </>
  );
};

export default Title;
