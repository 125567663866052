import React from 'react';
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from 'react-zoom-pan-pinch';
import styles from './open-images.module.scss';
import Icon from '../../elements/icon/Icon';
import { services } from '../infos/infos';

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <div className={styles.controlsContainer}>
      <button
        className={styles.controlButton}
        onClick={(e) => {
          zoomIn();
          e.stopPropagation();
        }}
      >
        <span className={styles.big}>Збільшити</span>
        <Icon class={styles.small} iconName='expand' />
      </button>
      <button
        className={styles.controlButton}
        onClick={(e) => {
          zoomOut();
          e.stopPropagation();
        }}
      >
        <span className={styles.big}>Зменшити</span>
        <Icon class={styles.small} iconName='collapse' />
      </button>
      <button
        className={styles.controlButton}
        onClick={(e) => {
          resetTransform();
          e.stopPropagation();
        }}
      >
        <span className={styles.big}>Скинути</span>
        <Icon class={styles.small} iconName='restart' />
      </button>
    </div>
  );
};
const OpenImagesWrapper = ({ setOpenImg, activeIndex, activeSliderId }) => {
  return (
    <div className={styles.openImagesWrapper} onClick={() => setOpenImg(false)}>
      {services.map((service) => (
        <div className={styles.openImagesContainer}>
          {activeSliderId === service.id &&
            service.photos &&
            service.photos
              .filter((item, index) => index === activeIndex)
              .map((filteredImage) => (
                <TransformWrapper>
                  <TransformComponent>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className={styles.openImageContainer}
                    >
                      <img src={filteredImage} />
                    </div>{' '}
                  </TransformComponent>
                  <Controls />
                </TransformWrapper>
              ))}
        </div>
      ))}
      <div className={styles.crossContainer}>
        <Icon iconName='cross' />
      </div>
    </div>
  );
};

export default OpenImagesWrapper;
