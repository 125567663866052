import React from 'react';
import styles from './nav-vertical.module.scss';
import { HashLink } from 'react-router-hash-link';

const NavVertical = React.memo(({setMenu}) => {
  return (
    <nav onClick={(e) => e.stopPropagation()} className={styles.nav}>
      <HashLink
        onClick={() => setMenu(false)}
        smooth
        className={styles.link}
        to='/#top'
      >
        Головна
      </HashLink>
      <HashLink
        onClick={() => setMenu(false)}
        smooth
        className={styles.link}
        to='/services#title'
      >
        Послуги
      </HashLink>
      <HashLink
        onClick={() => setMenu(false)}
        smooth
        className={styles.link}
        to='/portfolio#top'
      >
        Портфоліо
      </HashLink>
      <HashLink
        onClick={() => setMenu(false)}
        smooth
        className={styles.link}
        to='/#reviews'
      >
        Відгуки
      </HashLink>
      <HashLink
        onClick={() => setMenu(false)}
        smooth
        className={styles.link}
        to='#contacts'
      >
        Контакти
      </HashLink>
    </nav>
  );
});

export default NavVertical;
