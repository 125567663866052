// ! Imports
// ? 1. services
// Backgrounds
import orgklo from './../../assets/images/services/orgklo.jpg';
import box from './../../assets/images/services/box.jpg';
import stella from './../../assets/images/services/stella.jpg';
import kronshtein from './../../assets/images/services/kronshtein.jpg';
import lightLetters from './../../assets/images/services/light-letters.jpg';
import contrazhur from './../../assets/images/services/contrazhur.jpg';
import notLightLetters from './../../assets/images/services/not-light-letters.jpg';
import flatLetters from './../../assets/images/services/flat-letters.jpg';
import fotozone from './../../assets/images/services/fotozone.jpg';
import bannerAds from './../../assets/images/services/banner-ads.jpg';
import remakingOld from './../../assets/images/services/remaking-old.jpg';
import table from './../../assets/images/services/decor.jpg';
import stander from './../../assets/images/services/stander.jpg';
import city from './../../assets/images/services/citylights.jpg';
import branding from './../../assets/images/services/branding.jpg';
import metal from './../../assets/images/services/metal.jpg';

// ? 2. Photos - services
// Orgklo
import orgkloOne from './../../assets/images/services/orgklo/1.jpg';
import orgkloTwo from './../../assets/images/services/orgklo/2.jpg';
import orgkloThree from './../../assets/images/services/orgklo/3.jpg';
import orgkloFour from './../../assets/images/services/orgklo/4.jpg';
import orgkloOneSmall from './../../assets/images/services/orgklo/small/1.jpg';
import orgkloTwoSmall from './../../assets/images/services/orgklo/small/2.jpg';
import orgkloThreeSmall from './../../assets/images/services/orgklo/small/3.jpg';
import orgkloFourSmall from './../../assets/images/services/orgklo/small/4.jpg';

// Boxes
import boxesNull from './../../assets/images/services/boxes/0.jpg';
import boxesOne from './../../assets/images/services/boxes/1.jpg';
import boxesTwo from './../../assets/images/services/boxes/2.jpg';
import boxesThree from './../../assets/images/services/boxes/3.jpg';
import boxesFour from './../../assets/images/services/boxes/4.jpg';
import boxesFive from './../../assets/images/services/boxes/5.jpg';
import boxesNullSmall from './../../assets/images/services/boxes/small/0.jpg';
import boxesOneSmall from './../../assets/images/services/boxes/small/1.jpg';
import boxesTwoSmall from './../../assets/images/services/boxes/small/2.jpg';
import boxesThreeSmall from './../../assets/images/services/boxes/small/3.jpg';
import boxesFourSmall from './../../assets/images/services/boxes/small/4.jpg';
import boxesFiveSmall from './../../assets/images/services/boxes/small/5.jpg';

// Stella
import stellaOne from './../../assets/images/services/stella/1.jpg';
import stellaTwo from './../../assets/images/services/stella/2.jpg';
import stellaThree from './../../assets/images/services/stella/3.jpg';
import stellaFour from './../../assets/images/services/stella/4.jpg';
import stellaEleven from './../../assets/images/services/stella/11.jpg';
import stellaTwelve from './../../assets/images/services/stella/12.jpg';
import stellaThirteen from './../../assets/images/services/stella/13.jpg';
import stellaOneSmall from './../../assets/images/services/stella/small/1.jpg';
import stellaTwoSmall from './../../assets/images/services/stella/small/2.jpg';
import stellaThreeSmall from './../../assets/images/services/stella/small/3.jpg';
import stellaFourSmall from './../../assets/images/services/stella/small/4.jpg';
import stellaElevenSmall from './../../assets/images/services/stella/small/11.jpg';
import stellaTwelveSmall from './../../assets/images/services/stella/small/12.jpg';
import stellaThirteenSmall from './../../assets/images/services/stella/small/13.jpg';

// Kronshtein
import kronshteinOne from './../../assets/images/services/kronshtein/1.jpg';
import kronshteinTwo from './../../assets/images/services/kronshtein/2.jpg';
import kronshteinThree from './../../assets/images/services/kronshtein/3.jpg';
import kronshteinFour from './../../assets/images/services/kronshtein/4.jpg';
import kronshteinOneSmall from './../../assets/images/services/kronshtein/small/1.jpg';
import kronshteinTwoSmall from './../../assets/images/services/kronshtein/small/2.jpg';
import kronshteinThreeSmall from './../../assets/images/services/kronshtein/small/3.jpg';
import kronshteinFourSmall from './../../assets/images/services/kronshtein/small/4.jpg';

// Kontrazhur
import kontrazhurOne from './../../assets/images/services/kontazhur/1.jpg';
import kontrazhurTwo from './../../assets/images/services/kontazhur/2.jpg';
import kontrazhurThree from './../../assets/images/services/kontazhur/3.jpg';
import kontrazhurFour from './../../assets/images/services/kontazhur/4.jpg';
import kontrazhurOneSmall from './../../assets/images/services/kontazhur/small/1.jpg';
import kontrazhurTwoSmall from './../../assets/images/services/kontazhur/small/2.jpg';
import kontrazhurThreeSmall from './../../assets/images/services/kontazhur/small/3.jpg';
import kontrazhurFourSmall from './../../assets/images/services/kontazhur/small/4.jpg';

// Flat letters
import flatNull from './../../assets/images/services/flat-letters/0.jpg';
import flatOne from './../../assets/images/services/flat-letters/1.jpg';
import flatTwo from './../../assets/images/services/flat-letters/2.jpg';
import flatFour from './../../assets/images/services/flat-letters/4.jpg';
import flatFive from './../../assets/images/services/flat-letters/5.jpg';
import flatNullSmall from './../../assets/images/services/flat-letters/small/0.jpg';
import flatOneSmall from './../../assets/images/services/flat-letters/small/1.jpg';
import flatTwoSmall from './../../assets/images/services/flat-letters/small/2.jpg';
import flatFourSmall from './../../assets/images/services/flat-letters/small/4.jpg';
import flatFiveSmall from './../../assets/images/services/flat-letters/small/5.jpg';

// Fotozone
import fotozoneOne from './../../assets/images/services/fotozone/1.jpg';
import fotozoneTwo from './../../assets/images/services/fotozone/2.jpg';
import fotozoneThree from './../../assets/images/services/fotozone/3.jpg';
import fotozoneOneSmall from './../../assets/images/services/fotozone/small/1.jpg';
import fotozoneTwoSmall from './../../assets/images/services/fotozone/small/2.jpg';
import fotozoneThreeSmall from './../../assets/images/services/fotozone/small/3.jpg';

// Banner ads
import bannerAdOne from './../../assets/images/services/banner-ads/1.jpg';
import bannerAdTwo from './../../assets/images/services/banner-ads/2.jpg';
import bannerAdThree from './../../assets/images/services/banner-ads/3.jpg';
import bannerAdFour from './../../assets/images/services/banner-ads/4.jpg';
import bannerAdOneSmall from './../../assets/images/services/banner-ads/small/1.jpg';
import bannerAdTwoSmall from './../../assets/images/services/banner-ads/small/2.jpg';
import bannerAdThreeSmall from './../../assets/images/services/banner-ads/small/3.jpg';
import bannerAdFourSmall from './../../assets/images/services/banner-ads/small/4.jpg';

// Non-light letters
import nonLightOne from './../../assets/images/services/not-light-letters/1.jpg';
import nonLightTwo from './../../assets/images/services/not-light-letters/2.jpg';
import nonLightThree from './../../assets/images/services/not-light-letters/3.jpg';
import nonLightFour from './../../assets/images/services/not-light-letters/4.jpg';
import nonLightOneSmall from './../../assets/images/services/not-light-letters/small/1.jpg';
import nonLightTwoSmall from './../../assets/images/services/not-light-letters/small/2.jpg';
import nonLightThreeSmall from './../../assets/images/services/not-light-letters/small/3.jpg';
import nonLightFourSmall from './../../assets/images/services/not-light-letters/small/4.jpg';

// Linght letters
import lightOne from './../../assets/images/services/light-letters/1.jpg';
import lightTwo from './../../assets/images/services/light-letters/2.jpg';
import lightThree from './../../assets/images/services/light-letters/3.jpg';
import lightFour from './../../assets/images/services/light-letters/4.jpg';
import lightOneSmall from './../../assets/images/services/light-letters/small/1.jpg';
import lightTwoSmall from './../../assets/images/services/light-letters/small/2.jpg';
import lightThreeSmall from './../../assets/images/services/light-letters/small/3.jpg';
import lightFourSmall from './../../assets/images/services/light-letters/small/4.jpg';

// Remaking old
import remakingOne from './../../assets/images/services/remaking-old/1.jpg';
import remakingTwo from './../../assets/images/services/remaking-old/2.jpg';
import remakingThree from './../../assets/images/services/remaking-old/3.jpg';
import remakingOneSmall from './../../assets/images/services/remaking-old/small/1.jpg';
import remakingTwoSmall from './../../assets/images/services/remaking-old/small/2.jpg';
import remakingThreeSmall from './../../assets/images/services/remaking-old/small/3.jpg';

// Metal
import metalOne from './../../assets/images/services/metal/1.jpg';
import metalTwo from './../../assets/images/services/metal/2.jpg';
import metalThree from './../../assets/images/services/metal/3.jpg';
import metalSix from './../../assets/images/services/metal/6.jpg';
import metalOneSmall from './../../assets/images/services/metal/small/1.jpg';
import metalTwoSmall from './../../assets/images/services/metal/small/2.jpg';
import metalThreeSmall from './../../assets/images/services/metal/small/3.jpg';
import metalSixSmall from './../../assets/images/services/metal/small/6.jpg';

// Branding
import brandingOne from './../../assets/images/services/branding/1.jpg';
import brandingTwo from './../../assets/images/services/branding/2.jpg';
import brandingFour from './../../assets/images/services/branding/4.jpg';
import brandingOneSmall from './../../assets/images/services/branding/small/1.jpg';
import brandingTwoSmall from './../../assets/images/services/branding/small/2.jpg';
import brandingFourSmall from './../../assets/images/services/branding/small/4.jpg';

// city lights
import cityOne from './../../assets/images/services/citylights/1.jpg';
import cityTwo from './../../assets/images/services/citylights/2.jpg';
import cityThree from './../../assets/images/services/citylights/3.jpg';
import cityOneSmall from './../../assets/images/services/citylights/small/1.jpg';
import cityTwoSmall from './../../assets/images/services/citylights/small/2.jpg';
import cityThreeSmall from './../../assets/images/services/citylights/small/3.jpg';

// Decor
import decorFour from './../../assets/images/services/decor/4.jpg';
import decorFourSmall from './../../assets/images/services/decor/small/4.jpg';

// Tables
import tableOne from './../../assets/images/services/tables/1.jpg';
import tableTwo from './../../assets/images/services/tables/2.jpg';
import tableThree from './../../assets/images/services/tables/3.jpg';
import tableFour from './../../assets/images/services/tables/4.jpg';
import tableFive from './../../assets/images/services/tables/5.jpeg';
import tableSix from './../../assets/images/services/tables/6.jpg';
import tableSeven from './../../assets/images/services/tables/7.jpg';
import tableOneSmall from './../../assets/images/services/tables/small/1.jpg';
import tableTwoSmall from './../../assets/images/services/tables/small/2.jpg';
import tableThreeSmall from './../../assets/images/services/tables/small/3.jpg';
import tableFourSmall from './../../assets/images/services/tables/small/4.jpg';
import tableFiveSmall from './../../assets/images/services/tables/small/5.jpeg';
import tableSixSmall from './../../assets/images/services/tables/small/6.jpg';
import tableSevenSmall from './../../assets/images/services/tables/small/7.jpg';

// Standers
import standerOne from './../../assets/images/services/standers/1.jpg';
import standerTwo from './../../assets/images/services/standers/2.jpg';
import standerThree from './../../assets/images/services/standers/3.jpg';
import standerFour from './../../assets/images/services/standers/4.jpg';
import standerFive from './../../assets/images/services/standers/5.jpg';
import standerSix from './../../assets/images/services/standers/6.jpg';
import standerSeven from './../../assets/images/services/standers/7.jpg';
import standerOneSmall from './../../assets/images/services/standers/small/1.jpg';
import standerTwoSmall from './../../assets/images/services/standers/small/2.jpg';
import standerThreeSmall from './../../assets/images/services/standers/small/3.jpg';
import standerFourSmall from './../../assets/images/services/standers/small/4.jpg';
import standerFiveSmall from './../../assets/images/services/standers/small/5.jpg';
import standerSixSmall from './../../assets/images/services/standers/small/6.jpg';
import standerSevenSmall from './../../assets/images/services/standers/small/7.jpg';

// ? 4. remaking ads
import cleaning from './../../assets/animations/cleaning.lottie';
import brush from './../../assets/animations/brush.lottie';
import repair from './../../assets/animations/repair.lottie';
import light from './../../assets/animations/light.lottie';

// ? Portfolio
// Big images
import big1 from './../../assets/images/portfolio/big(1).jpg';
import big2 from './../../assets/images/portfolio/big(2).jpg';
import big3 from './../../assets/images/portfolio/big(3).jpg';
import big4 from './../../assets/images/portfolio/big(4).jpg';
import big5 from './../../assets/images/portfolio/big(5).jpg';
import big6 from './../../assets/images/portfolio/big(6).jpg';
import big7 from './../../assets/images/portfolio/big(7).jpg';
import big8 from './../../assets/images/portfolio/big(8).jpg';
import big9 from './../../assets/images/portfolio/big(9).jpg';
import big10 from './../../assets/images/portfolio/big(10).jpg';
import big11 from './../../assets/images/portfolio/big(11).jpg';
import big12 from './../../assets/images/portfolio/big(12).jpg';
import big13 from './../../assets/images/portfolio/big(13).jpg';
import big14 from './../../assets/images/portfolio/big(14).jpg';
import big15 from './../../assets/images/portfolio/big(15).jpg';
import big16 from './../../assets/images/portfolio/big(16).jpg';
import big17 from './../../assets/images/portfolio/big(17).jpg';
import big18 from './../../assets/images/portfolio/big(18).jpg';
import big19 from './../../assets/images/portfolio/big(19).jpg';
import big20 from './../../assets/images/portfolio/big(20).jpg';
import big21 from './../../assets/images/portfolio/big(21).jpg';
import big22 from './../../assets/images/portfolio/big(22).jpg';
import big23 from './../../assets/images/portfolio/big(23).jpg';
import big24 from './../../assets/images/portfolio/big(24).jpg';
import big25 from './../../assets/images/portfolio/big(25).jpg';
import big26 from './../../assets/images/portfolio/big(26).jpg';
import big27 from './../../assets/images/portfolio/big(27).jpg';
import big28 from './../../assets/images/portfolio/big(28).jpg';
import big29 from './../../assets/images/portfolio/big(29).jpg';
import big30 from './../../assets/images/portfolio/big(30).jpg';
import big31 from './../../assets/images/portfolio/big(31).jpg';
import big32 from './../../assets/images/portfolio/big(32).jpg';
import big33 from './../../assets/images/portfolio/big(33).jpg';
import big34 from './../../assets/images/portfolio/big(34).jpg';
import big35 from './../../assets/images/portfolio/big(35).jpg';
import big36 from './../../assets/images/portfolio/big(36).jpg';
import big37 from './../../assets/images/portfolio/big(37).jpg';
import big38 from './../../assets/images/portfolio/big(38).jpg';
import big39 from './../../assets/images/portfolio/big(39).jpg';
import big40 from './../../assets/images/portfolio/big(40).jpg';
import big41 from './../../assets/images/portfolio/big(41).jpg';
import big42 from './../../assets/images/portfolio/big(42).jpg';
import big43 from './../../assets/images/portfolio/big(43).jpg';
import big44 from './../../assets/images/portfolio/big(44).jpg';
import big45 from './../../assets/images/portfolio/big(45).jpg';
import big46 from './../../assets/images/portfolio/big(46).jpg';
import big47 from './../../assets/images/portfolio/big(47).jpg';
import big48 from './../../assets/images/portfolio/big(48).jpg';
import big49 from './../../assets/images/portfolio/big(49).jpg';
import big50 from './../../assets/images/portfolio/big(50).jpg';
import big51 from './../../assets/images/portfolio/big(51).jpg';
import big52 from './../../assets/images/portfolio/big(52).jpg';
import big53 from './../../assets/images/portfolio/big(53).jpg';
import big54 from './../../assets/images/portfolio/big(54).jpg';
import big55 from './../../assets/images/portfolio/big(55).jpg';
import big56 from './../../assets/images/portfolio/big(56).jpg';
import big57 from './../../assets/images/portfolio/big(57).jpg';
import big58 from './../../assets/images/portfolio/big(58).jpg';
import big59 from './../../assets/images/portfolio/big(59).jpg';
import big60 from './../../assets/images/portfolio/big(60).jpg';
import big61 from './../../assets/images/portfolio/big(61).jpg';
import big62 from './../../assets/images/portfolio/big(62).jpg';
import big63 from './../../assets/images/portfolio/big(63).jpg';
import big64 from './../../assets/images/portfolio/big(64).jpg';
import big65 from './../../assets/images/portfolio/big(65).jpg';
import big66 from './../../assets/images/portfolio/big(66).jpg';
import big67 from './../../assets/images/portfolio/big(67).jpg';
import big68 from './../../assets/images/portfolio/big(68).jpg';
import big69 from './../../assets/images/portfolio/big(69).jpg';
import big70 from './../../assets/images/portfolio/big(70).jpg';
import big71 from './../../assets/images/portfolio/big(71).jpg';
import big72 from './../../assets/images/portfolio/big(72).jpg';
import big73 from './../../assets/images/portfolio/big(73).jpg';
import big74 from './../../assets/images/portfolio/big(74).jpg';
import big75 from './../../assets/images/portfolio/big(75).jpg';
import big76 from './../../assets/images/portfolio/big(76).jpg';
import big77 from './../../assets/images/portfolio/big(77).jpg';
import big78 from './../../assets/images/portfolio/big(78).jpg';
import big79 from './../../assets/images/portfolio/big(79).jpg';
import big80 from './../../assets/images/portfolio/big(80).jpg';
import big81 from './../../assets/images/portfolio/big(81).jpg';
import big82 from './../../assets/images/portfolio/big(82).jpg';
import big83 from './../../assets/images/portfolio/big(83).jpg';
import big84 from './../../assets/images/portfolio/big(84).jpg';
import big85 from './../../assets/images/portfolio/big(85).jpg';
import big86 from './../../assets/images/portfolio/big(86).jpg';
import big87 from './../../assets/images/portfolio/big(87).jpg';
import big88 from './../../assets/images/portfolio/big(88).jpg';
import big89 from './../../assets/images/portfolio/big(89).jpg';
import big90 from './../../assets/images/portfolio/big(90).jpg';
import big91 from './../../assets/images/portfolio/big(91).jpg';
import big92 from './../../assets/images/portfolio/big(92).jpg';
import big93 from './../../assets/images/portfolio/big(93).jpg';
import big94 from './../../assets/images/portfolio/big(94).jpg';
import big95 from './../../assets/images/portfolio/big(95).jpg';
import big96 from './../../assets/images/portfolio/big(96).jpg';
import big97 from './../../assets/images/portfolio/big(97).jpg';
import big98 from './../../assets/images/portfolio/big(98).jpg';
import big99 from './../../assets/images/portfolio/big(99).jpg';
import big100 from './../../assets/images/portfolio/big(100).jpg';
import big101 from './../../assets/images/portfolio/big(101).jpg';
import big102 from './../../assets/images/portfolio/big(102).jpg';
import big103 from './../../assets/images/portfolio/big(103).jpg';
import big104 from './../../assets/images/portfolio/big(104).jpg';
import big105 from './../../assets/images/portfolio/big(105).jpg';
import big106 from './../../assets/images/portfolio/big(106).jpg';
import big107 from './../../assets/images/portfolio/big(107).jpg';
import big108 from './../../assets/images/portfolio/big(108).jpg';
import big109 from './../../assets/images/portfolio/big(109).jpg';
import big110 from './../../assets/images/portfolio/big(110).jpg';
import big111 from './../../assets/images/portfolio/big(111).jpg';
import big112 from './../../assets/images/portfolio/big(112).jpg';
import big113 from './../../assets/images/portfolio/big(113).jpg';
import big114 from './../../assets/images/portfolio/big(114).jpg';
import big115 from './../../assets/images/portfolio/big(115).jpg';
import big116 from './../../assets/images/portfolio/big(116).jpg';
import big117 from './../../assets/images/portfolio/big(117).jpg';
import big118 from './../../assets/images/portfolio/big(118).jpg';
import big119 from './../../assets/images/portfolio/big(119).jpg';
import big120 from './../../assets/images/portfolio/big(120).jpg';
import big121 from './../../assets/images/portfolio/big(121).jpg';
import big122 from './../../assets/images/portfolio/big(122).jpg';
import big123 from './../../assets/images/portfolio/big(123).jpg';
import big124 from './../../assets/images/portfolio/big(124).jpg';
import big125 from './../../assets/images/portfolio/big(125).jpg';
import big126 from './../../assets/images/portfolio/big(126).jpg';
import big127 from './../../assets/images/portfolio/big(127).jpg';
import big128 from './../../assets/images/portfolio/big(128).jpg';
import big129 from './../../assets/images/portfolio/big(129).jpg';
import big130 from './../../assets/images/portfolio/big(130).jpg';
import big131 from './../../assets/images/portfolio/big(131).jpg';
import big132 from './../../assets/images/portfolio/big(132).jpg';
import big133 from './../../assets/images/portfolio/big(133).jpg';
import big134 from './../../assets/images/portfolio/big(134).jpg';
import big135 from './../../assets/images/portfolio/big(135).jpg';
import big136 from './../../assets/images/portfolio/big(136).jpg';
import big137 from './../../assets/images/portfolio/big(137).jpg';
import big138 from './../../assets/images/portfolio/big(138).jpg';
import bigSmall1 from './../../assets/images/portfolio/small/big(1).jpg';
import bigSmall2 from './../../assets/images/portfolio/small/big(2).jpg';
import bigSmall3 from './../../assets/images/portfolio/small/big(3).jpg';
import bigSmall4 from './../../assets/images/portfolio/small/big(4).jpg';
import bigSmall5 from './../../assets/images/portfolio/small/big(5).jpg';
import bigSmall6 from './../../assets/images/portfolio/small/big(6).jpg';
import bigSmall7 from './../../assets/images/portfolio/small/big(7).jpg';
import bigSmall8 from './../../assets/images/portfolio/small/big(8).jpg';
import bigSmall9 from './../../assets/images/portfolio/small/big(9).jpg';
import bigSmall10 from './../../assets/images/portfolio/small/big(10).jpg';
import bigSmall11 from './../../assets/images/portfolio/small/big(11).jpg';
import bigSmall12 from './../../assets/images/portfolio/small/big(12).jpg';
import bigSmall13 from './../../assets/images/portfolio/small/big(13).jpg';
import bigSmall14 from './../../assets/images/portfolio/small/big(14).jpg';
import bigSmall15 from './../../assets/images/portfolio/small/big(15).jpg';
import bigSmall16 from './../../assets/images/portfolio/small/big(16).jpg';
import bigSmall17 from './../../assets/images/portfolio/small/big(17).jpg';
import bigSmall18 from './../../assets/images/portfolio/small/big(18).jpg';
import bigSmall19 from './../../assets/images/portfolio/small/big(19).jpg';
import bigSmall20 from './../../assets/images/portfolio/small/big(20).jpg';
import bigSmall21 from './../../assets/images/portfolio/small/big(21).jpg';
import bigSmall22 from './../../assets/images/portfolio/small/big(22).jpg';
import bigSmall23 from './../../assets/images/portfolio/small/big(23).jpg';
import bigSmall24 from './../../assets/images/portfolio/small/big(24).jpg';
import bigSmall25 from './../../assets/images/portfolio/small/big(25).jpg';
import bigSmall26 from './../../assets/images/portfolio/small/big(26).jpg';
import bigSmall27 from './../../assets/images/portfolio/small/big(27).jpg';
import bigSmall28 from './../../assets/images/portfolio/small/big(28).jpg';
import bigSmall29 from './../../assets/images/portfolio/small/big(29).jpg';
import bigSmall30 from './../../assets/images/portfolio/small/big(30).jpg';
import bigSmall31 from './../../assets/images/portfolio/small/big(31).jpg';
import bigSmall32 from './../../assets/images/portfolio/small/big(32).jpg';
import bigSmall33 from './../../assets/images/portfolio/small/big(33).jpg';
import bigSmall34 from './../../assets/images/portfolio/small/big(34).jpg';
import bigSmall35 from './../../assets/images/portfolio/small/big(35).jpg';
import bigSmall36 from './../../assets/images/portfolio/small/big(36).jpg';
import bigSmall37 from './../../assets/images/portfolio/small/big(37).jpg';
import bigSmall38 from './../../assets/images/portfolio/small/big(38).jpg';
import bigSmall39 from './../../assets/images/portfolio/small/big(39).jpg';
import bigSmall40 from './../../assets/images/portfolio/small/big(40).jpg';
import bigSmall41 from './../../assets/images/portfolio/small/big(41).jpg';
import bigSmall42 from './../../assets/images/portfolio/small/big(42).jpg';
import bigSmall43 from './../../assets/images/portfolio/small/big(43).jpg';
import bigSmall44 from './../../assets/images/portfolio/small/big(44).jpg';
import bigSmall45 from './../../assets/images/portfolio/small/big(45).jpg';
import bigSmall46 from './../../assets/images/portfolio/small/big(46).jpg';
import bigSmall47 from './../../assets/images/portfolio/small/big(47).jpg';
import bigSmall48 from './../../assets/images/portfolio/small/big(48).jpg';
import bigSmall49 from './../../assets/images/portfolio/small/big(49).jpg';
import bigSmall50 from './../../assets/images/portfolio/small/big(50).jpg';
import bigSmall51 from './../../assets/images/portfolio/small/big(51).jpg';
import bigSmall52 from './../../assets/images/portfolio/small/big(52).jpg';
import bigSmall53 from './../../assets/images/portfolio/small/big(53).jpg';
import bigSmall54 from './../../assets/images/portfolio/small/big(54).jpg';
import bigSmall55 from './../../assets/images/portfolio/small/big(55).jpg';
import bigSmall56 from './../../assets/images/portfolio/small/big(56).jpg';
import bigSmall57 from './../../assets/images/portfolio/small/big(57).jpg';
import bigSmall58 from './../../assets/images/portfolio/small/big(58).jpg';
import bigSmall59 from './../../assets/images/portfolio/small/big(59).jpg';
import bigSmall60 from './../../assets/images/portfolio/small/big(60).jpg';
import bigSmall61 from './../../assets/images/portfolio/small/big(61).jpg';
import bigSmall62 from './../../assets/images/portfolio/small/big(62).jpg';
import bigSmall63 from './../../assets/images/portfolio/small/big(63).jpg';
import bigSmall64 from './../../assets/images/portfolio/small/big(64).jpg';
import bigSmall65 from './../../assets/images/portfolio/small/big(65).jpg';
import bigSmall66 from './../../assets/images/portfolio/small/big(66).jpg';
import bigSmall67 from './../../assets/images/portfolio/small/big(67).jpg';
import bigSmall68 from './../../assets/images/portfolio/small/big(68).jpg';
import bigSmall69 from './../../assets/images/portfolio/small/big(69).jpg';
import bigSmall70 from './../../assets/images/portfolio/small/big(70).jpg';
import bigSmall71 from './../../assets/images/portfolio/small/big(71).jpg';
import bigSmall72 from './../../assets/images/portfolio/small/big(72).jpg';
import bigSmall73 from './../../assets/images/portfolio/small/big(73).jpg';
import bigSmall74 from './../../assets/images/portfolio/small/big(74).jpg';
import bigSmall75 from './../../assets/images/portfolio/small/big(75).jpg';
import bigSmall76 from './../../assets/images/portfolio/small/big(76).jpg';
import bigSmall77 from './../../assets/images/portfolio/small/big(77).jpg';
import bigSmall78 from './../../assets/images/portfolio/small/big(78).jpg';
import bigSmall79 from './../../assets/images/portfolio/small/big(79).jpg';
import bigSmall80 from './../../assets/images/portfolio/small/big(80).jpg';
import bigSmall81 from './../../assets/images/portfolio/small/big(81).jpg';
import bigSmall82 from './../../assets/images/portfolio/small/big(82).jpg';
import bigSmall83 from './../../assets/images/portfolio/small/big(83).jpg';
import bigSmall84 from './../../assets/images/portfolio/small/big(84).jpg';
import bigSmall85 from './../../assets/images/portfolio/small/big(85).jpg';
import bigSmall86 from './../../assets/images/portfolio/small/big(86).jpg';
import bigSmall87 from './../../assets/images/portfolio/small/big(87).jpg';
import bigSmall88 from './../../assets/images/portfolio/small/big(88).jpg';
import bigSmall89 from './../../assets/images/portfolio/small/big(89).jpg';
import bigSmall90 from './../../assets/images/portfolio/small/big(90).jpg';
import bigSmall91 from './../../assets/images/portfolio/small/big(91).jpg';
import bigSmall92 from './../../assets/images/portfolio/small/big(92).jpg';
import bigSmall93 from './../../assets/images/portfolio/small/big(93).jpg';
import bigSmall94 from './../../assets/images/portfolio/small/big(94).jpg';
import bigSmall95 from './../../assets/images/portfolio/small/big(95).jpg';
import bigSmall96 from './../../assets/images/portfolio/small/big(96).jpg';
import bigSmall97 from './../../assets/images/portfolio/small/big(97).jpg';
import bigSmall98 from './../../assets/images/portfolio/small/big(98).jpg';
import bigSmall99 from './../../assets/images/portfolio/small/big(99).jpg';
import bigSmall100 from './../../assets/images/portfolio/small/big(100).jpg';
import bigSmall101 from './../../assets/images/portfolio/small/big(101).jpg';
import bigSmall102 from './../../assets/images/portfolio/small/big(102).jpg';
import bigSmall103 from './../../assets/images/portfolio/small/big(103).jpg';
import bigSmall104 from './../../assets/images/portfolio/small/big(104).jpg';
import bigSmall105 from './../../assets/images/portfolio/small/big(105).jpg';
import bigSmall106 from './../../assets/images/portfolio/small/big(106).jpg';
import bigSmall107 from './../../assets/images/portfolio/small/big(107).jpg';
import bigSmall108 from './../../assets/images/portfolio/small/big(108).jpg';
import bigSmall109 from './../../assets/images/portfolio/small/big(109).jpg';
import bigSmall110 from './../../assets/images/portfolio/small/big(110).jpg';
import bigSmall111 from './../../assets/images/portfolio/small/big(111).jpg';
import bigSmall112 from './../../assets/images/portfolio/small/big(112).jpg';
import bigSmall113 from './../../assets/images/portfolio/small/big(113).jpg';
import bigSmall114 from './../../assets/images/portfolio/small/big(114).jpg';
import bigSmall115 from './../../assets/images/portfolio/small/big(115).jpg';
import bigSmall116 from './../../assets/images/portfolio/small/big(116).jpg';
import bigSmall117 from './../../assets/images/portfolio/small/big(117).jpg';
import bigSmall118 from './../../assets/images/portfolio/small/big(118).jpg';
import bigSmall119 from './../../assets/images/portfolio/small/big(119).jpg';
import bigSmall120 from './../../assets/images/portfolio/small/big(120).jpg';
import bigSmall121 from './../../assets/images/portfolio/small/big(121).jpg';
import bigSmall122 from './../../assets/images/portfolio/small/big(122).jpg';
import bigSmall123 from './../../assets/images/portfolio/small/big(123).jpg';
import bigSmall124 from './../../assets/images/portfolio/small/big(124).jpg';
import bigSmall125 from './../../assets/images/portfolio/small/big(125).jpg';
import bigSmall126 from './../../assets/images/portfolio/small/big(126).jpg';
import bigSmall127 from './../../assets/images/portfolio/small/big(127).jpg';
import bigSmall128 from './../../assets/images/portfolio/small/big(128).jpg';
import bigSmall129 from './../../assets/images/portfolio/small/big(129).jpg';
import bigSmall130 from './../../assets/images/portfolio/small/big(130).jpg';
import bigSmall131 from './../../assets/images/portfolio/small/big(131).jpg';
import bigSmall132 from './../../assets/images/portfolio/small/big(132).jpg';
import bigSmall133 from './../../assets/images/portfolio/small/big(133).jpg';
import bigSmall134 from './../../assets/images/portfolio/small/big(134).jpg';
import bigSmall135 from './../../assets/images/portfolio/small/big(135).jpg';
import bigSmall136 from './../../assets/images/portfolio/small/big(136).jpg';
import bigSmall137 from './../../assets/images/portfolio/small/big(137).jpg';
import bigSmall138 from './../../assets/images/portfolio/small/big(138).jpg';

export const images = [
  {
    id: 1,
    image: big1,
    imageSmall: bigSmall1,
    alt: 'image',
  },
  {
    id: 2,
    image: big2,
    imageSmall: bigSmall2,
    alt: 'image',
  },
  {
    id: 3,
    image: big3,
    imageSmall: bigSmall3,
    alt: 'image',
  },
  {
    id: 4,
    image: big4,
    imageSmall: bigSmall4,
    alt: 'image',
  },
  {
    id: 5,
    image: big5,
    imageSmall: bigSmall5,
    alt: 'image',
  },
  {
    id: 6,
    image: big6,
    imageSmall: bigSmall6,
    alt: 'image',
  },
  {
    id: 7,
    image: big7,
    imageSmall: bigSmall7,
    alt: 'image',
  },
  {
    id: 8,
    image: big8,
    imageSmall: bigSmall8,
    alt: 'image',
  },
  {
    id: 9,
    image: big9,
    imageSmall: bigSmall9,
    alt: 'image',
  },
  {
    id: 10,
    image: big10,
    imageSmall: bigSmall10,
    alt: 'image',
  },
  {
    id: 11,
    image: big11,
    imageSmall: bigSmall11,
    alt: 'image',
  },
  {
    id: 12,
    image: big12,
    imageSmall: bigSmall12,
    alt: 'image',
  },
  {
    id: 13,
    image: big13,
    imageSmall: bigSmall13,
    alt: 'image',
  },
  {
    id: 14,
    image: big14,
    imageSmall: bigSmall14,
    alt: 'image',
  },
  {
    id: 15,
    image: big15,
    imageSmall: bigSmall15,
    alt: 'image',
  },
  {
    id: 16,
    image: big16,
    imageSmall: bigSmall16,
    alt: 'image',
  },
  {
    id: 17,
    image: big17,
    imageSmall: bigSmall17,
    alt: 'image',
  },
  {
    id: 18,
    image: big18,
    imageSmall: bigSmall18,
    alt: 'image',
  },
  {
    id: 19,
    image: big19,
    imageSmall: bigSmall19,
    alt: 'image',
  },
  {
    id: 20,
    image: big20,
    imageSmall: bigSmall20,
    alt: 'image',
  },
  {
    id: 21,
    image: big21,
    imageSmall: bigSmall21,
    alt: 'image',
  },
  {
    id: 22,
    image: big22,
    imageSmall: bigSmall22,
    alt: 'image',
  },
  {
    id: 23,
    image: big23,
    imageSmall: bigSmall23,
    alt: 'image',
  },
  {
    id: 24,
    image: big24,
    imageSmall: bigSmall24,
    alt: 'image',
  },
  {
    id: 25,
    image: big25,
    imageSmall: bigSmall25,
    alt: 'image',
  },
  {
    id: 26,
    image: big26,
    imageSmall: bigSmall26,
    alt: 'image',
  },
  {
    id: 27,
    image: big27,
    imageSmall: bigSmall27,
    alt: 'image',
  },
  {
    id: 28,
    image: big28,
    imageSmall: bigSmall28,
    alt: 'image',
  },
  {
    id: 29,
    image: big29,
    imageSmall: bigSmall29,
    alt: 'image',
  },
  {
    id: 30,
    image: big30,
    imageSmall: bigSmall30,
    alt: 'image',
  },
  {
    id: 31,
    image: big31,
    imageSmall: bigSmall31,
    alt: 'image',
  },
  {
    id: 32,
    image: big32,
    imageSmall: bigSmall32,
    alt: 'image',
  },
  {
    id: 33,
    image: big33,
    imageSmall: bigSmall33,
    alt: 'image',
  },
  {
    id: 34,
    image: big34,
    imageSmall: bigSmall34,
    alt: 'image',
  },
  {
    id: 35,
    image: big35,
    imageSmall: bigSmall35,
    alt: 'image',
  },
  {
    id: 36,
    image: big36,
    imageSmall: bigSmall36,
    alt: 'image',
  },
  {
    id: 37,
    image: big37,
    imageSmall: bigSmall37,
    alt: 'image',
  },
  {
    id: 38,
    image: big38,
    imageSmall: bigSmall38,
    alt: 'image',
  },
  {
    id: 39,
    image: big39,
    imageSmall: bigSmall39,
    alt: 'image',
  },
  {
    id: 40,
    image: big40,
    imageSmall: bigSmall40,
    alt: 'image',
  },
  {
    id: 41,
    image: big41,
    imageSmall: bigSmall41,
    alt: 'image',
  },
  {
    id: 42,
    image: big42,
    imageSmall: bigSmall42,
    alt: 'image',
  },
  {
    id: 43,
    image: big43,
    imageSmall: bigSmall43,
    alt: 'image',
  },
  {
    id: 44,
    image: big44,
    imageSmall: bigSmall44,
    alt: 'image',
  },
  {
    id: 45,
    image: big45,
    imageSmall: bigSmall45,
    alt: 'image',
  },
  {
    id: 46,
    image: big46,
    imageSmall: bigSmall46,
    alt: 'image',
  },
  {
    id: 47,
    image: big47,
    imageSmall: bigSmall47,
    alt: 'image',
  },
  {
    id: 48,
    image: big48,
    imageSmall: bigSmall48,
    alt: 'image',
  },
  {
    id: 49,
    image: big49,
    imageSmall: bigSmall49,
    alt: 'image',
  },
  {
    id: 50,
    image: big50,
    imageSmall: bigSmall50,
    alt: 'image',
  },
  {
    id: 51,
    image: big51,
    imageSmall: bigSmall51,
    alt: 'image',
  },
  {
    id: 52,
    image: big52,
    imageSmall: bigSmall52,
    alt: 'image',
  },
  {
    id: 53,
    image: big53,
    imageSmall: bigSmall53,
    alt: 'image',
  },
  {
    id: 54,
    image: big54,
    imageSmall: bigSmall54,
    alt: 'image',
  },
  {
    id: 55,
    image: big55,
    imageSmall: bigSmall55,
    alt: 'image',
  },
  {
    id: 56,
    image: big56,
    imageSmall: bigSmall56,
    alt: 'image',
  },
  {
    id: 57,
    image: big57,
    imageSmall: bigSmall57,
    alt: 'image',
  },
  {
    id: 58,
    image: big58,
    imageSmall: bigSmall58,
    alt: 'image',
  },
  {
    id: 59,
    image: big59,
    imageSmall: bigSmall59,
    alt: 'image',
  },
  {
    id: 60,
    image: big60,
    imageSmall: bigSmall60,
    alt: 'image',
  },
  {
    id: 61,
    image: big61,
    imageSmall: bigSmall61,
    alt: 'image',
  },
  {
    id: 62,
    image: big62,
    imageSmall: bigSmall62,
    alt: 'image',
  },
  {
    id: 63,
    image: big63,
    imageSmall: bigSmall63,
    alt: 'image',
  },
  {
    id: 64,
    image: big64,
    imageSmall: bigSmall64,
    alt: 'image',
  },
  {
    id: 65,
    image: big65,
    imageSmall: bigSmall65,
    alt: 'image',
  },
  {
    id: 66,
    image: big66,
    imageSmall: bigSmall66,
    alt: 'image',
  },
  {
    id: 67,
    image: big67,
    imageSmall: bigSmall67,
    alt: 'image',
  },
  {
    id: 68,
    image: big68,
    imageSmall: bigSmall68,
    alt: 'image',
  },
  {
    id: 69,
    image: big69,
    imageSmall: bigSmall69,
    alt: 'image',
  },
  {
    id: 70,
    image: big70,
    imageSmall: bigSmall70,
    alt: 'image',
  },
  {
    id: 71,
    image: big71,
    imageSmall: bigSmall71,
    alt: 'image',
  },
  {
    id: 72,
    image: big72,
    imageSmall: bigSmall72,
    alt: 'image',
  },
  {
    id: 73,
    image: big73,
    imageSmall: bigSmall73,
    alt: 'image',
  },
  {
    id: 74,
    image: big74,
    imageSmall: bigSmall74,
    alt: 'image',
  },
  {
    id: 75,
    image: big75,
    imageSmall: bigSmall75,
    alt: 'image',
  },
  {
    id: 76,
    image: big76,
    imageSmall: bigSmall76,
    alt: 'image',
  },
  {
    id: 77,
    image: big77,
    imageSmall: bigSmall77,
    alt: 'image',
  },
  {
    id: 78,
    image: big78,
    imageSmall: bigSmall78,
    alt: 'image',
  },
  {
    id: 79,
    image: big79,
    imageSmall: bigSmall79,
    alt: 'image',
  },
  {
    id: 80,
    image: big80,
    imageSmall: bigSmall80,
    alt: 'image',
  },
  {
    id: 81,
    image: big81,
    imageSmall: bigSmall81,
    alt: 'image',
  },
  {
    id: 82,
    image: big82,
    imageSmall: bigSmall82,
    alt: 'image',
  },
  {
    id: 83,
    image: big83,
    imageSmall: bigSmall83,
    alt: 'image',
  },
  {
    id: 84,
    image: big84,
    imageSmall: bigSmall84,
    alt: 'image',
  },
  {
    id: 85,
    image: big85,
    imageSmall: bigSmall85,
    alt: 'image',
  },
  {
    id: 86,
    image: big86,
    imageSmall: bigSmall86,
    alt: 'image',
  },
  {
    id: 87,
    image: big87,
    imageSmall: bigSmall87,
    alt: 'image',
  },
  {
    id: 88,
    image: big88,
    imageSmall: bigSmall88,
    alt: 'image',
  },
  {
    id: 89,
    image: big89,
    imageSmall: bigSmall89,
    alt: 'image',
  },
  {
    id: 90,
    image: big90,
    imageSmall: bigSmall90,
    alt: 'image',
  },
  {
    id: 91,
    image: big91,
    imageSmall: bigSmall91,
    alt: 'image',
  },
  {
    id: 92,
    image: big92,
    imageSmall: bigSmall92,
    alt: 'image',
  },
  {
    id: 93,
    image: big93,
    imageSmall: bigSmall93,
    alt: 'image',
  },
  {
    id: 94,
    image: big94,
    imageSmall: bigSmall94,
    alt: 'image',
  },
  {
    id: 95,
    image: big95,
    imageSmall: bigSmall95,
    alt: 'image',
  },
  {
    id: 96,
    image: big96,
    imageSmall: bigSmall96,
    alt: 'image',
  },
  {
    id: 97,
    image: big97,
    imageSmall: bigSmall97,
    alt: 'image',
  },
  {
    id: 98,
    image: big98,
    imageSmall: bigSmall98,
    alt: 'image',
  },
  {
    id: 99,
    image: big99,
    imageSmall: bigSmall99,
    alt: 'image',
  },
  {
    id: 100,
    image: big100,
    imageSmall: bigSmall100,
    alt: 'image',
  },
  {
    id: 101,
    image: big101,
    imageSmall: bigSmall101,
    alt: 'image',
  },
  {
    id: 102,
    image: big102,
    imageSmall: bigSmall102,
    alt: 'image',
  },
  {
    id: 103,
    image: big103,
    imageSmall: bigSmall103,
    alt: 'image',
  },
  {
    id: 104,
    image: big104,
    imageSmall: bigSmall104,
    alt: 'image',
  },
  {
    id: 105,
    image: big105,
    imageSmall: bigSmall105,
    alt: 'image',
  },
  {
    id: 106,
    image: big106,
    imageSmall: bigSmall106,
    alt: 'image',
  },
  {
    id: 107,
    image: big107,
    imageSmall: bigSmall107,
    alt: 'image',
  },
  {
    id: 108,
    image: big108,
    imageSmall: bigSmall108,
    alt: 'image',
  },
  {
    id: 109,
    image: big109,
    imageSmall: bigSmall109,
    alt: 'image',
  },
  {
    id: 110,
    image: big110,
    imageSmall: bigSmall110,
    alt: 'image',
  },
  {
    id: 111,
    image: big111,
    imageSmall: bigSmall111,
    alt: 'image',
  },
  {
    id: 112,
    image: big112,
    imageSmall: bigSmall112,
    alt: 'image',
  },
  {
    id: 113,
    image: big113,
    imageSmall: bigSmall113,
    alt: 'image',
  },
  {
    id: 114,
    image: big114,
    imageSmall: bigSmall114,
    alt: 'image',
  },
  {
    id: 115,
    image: big115,
    imageSmall: bigSmall115,
    alt: 'image',
  },
  {
    id: 116,
    image: big116,
    imageSmall: bigSmall116,
    alt: 'image',
  },
  {
    id: 117,
    image: big117,
    imageSmall: bigSmall117,
    alt: 'image',
  },
  {
    id: 118,
    image: big118,
    imageSmall: bigSmall118,
    alt: 'image',
  },
  {
    id: 119,
    image: big119,
    imageSmall: bigSmall119,
    alt: 'image',
  },
  {
    id: 120,
    image: big120,
    imageSmall: bigSmall120,
    alt: 'image',
  },
  {
    id: 121,
    image: big121,
    imageSmall: bigSmall121,
    alt: 'image',
  },
  {
    id: 122,
    image: big122,
    imageSmall: bigSmall122,
    alt: 'image',
  },
  {
    id: 123,
    image: big123,
    imageSmall: bigSmall123,
    alt: 'image',
  },
  {
    id: 124,
    image: big124,
    imageSmall: bigSmall124,
    alt: 'image',
  },
  {
    id: 125,
    image: big125,
    imageSmall: bigSmall125,
    alt: 'image',
  },
  {
    id: 126,
    image: big126,
    imageSmall: bigSmall126,
    alt: 'image',
  },
  {
    id: 127,
    image: big127,
    imageSmall: bigSmall127,
    alt: 'image',
  },
  {
    id: 128,
    image: big128,
    imageSmall: bigSmall128,
    alt: 'image',
  },
  {
    id: 129,
    image: big129,
    imageSmall: bigSmall129,
    alt: 'image',
  },
  {
    id: 130,
    image: big130,
    imageSmall: bigSmall130,
    alt: 'image',
  },
  {
    id: 131,
    image: big131,
    imageSmall: bigSmall131,
    alt: 'image',
  },
  {
    id: 132,
    image: big132,
    imageSmall: bigSmall132,
    alt: 'image',
  },
  {
    id: 133,
    image: big133,
    imageSmall: bigSmall133,
    alt: 'image',
  },
  {
    id: 134,
    image: big134,
    imageSmall: bigSmall134,
    alt: 'image',
  },
  {
    id: 135,
    image: big135,
    imageSmall: bigSmall135,
    alt: 'image',
  },
  {
    id: 136,
    image: big136,
    imageSmall: bigSmall136,
    alt: 'image',
  },
  {
    id: 137,
    image: big137,
    imageSmall: bigSmall137,
    alt: 'image',
  },
  {
    id: 138,
    image: big138,
    imageSmall: bigSmall138,
    alt: 'image',
  },
];

// ! Steps
export const steps = [
  {
    title: 'Фото фасаду',
    text: 'бажано зблизька та трохи далі',
  },
  {
    title: 'Розміри',
    text: 'майбутньої реклами або наявного місця під неї',
  },
  {
    title: 'Уявлення, яка вона має бути :)',
    text: 'Або хороший настрій, щоб разом придумати.',
  },
];

// ! Reviews
export const reviews = [
  {
    name: 'Павло Дмитрук',
    text: "Відкривали магазин м'яса. Звернулися за вивіскою в різні компанії, але Бумеранг запропонував просто 'бомбу' дизайн, не встояли, замовили, залишились Мега задоволеними. Все супер - дизайн, світло, кольори. Молодці. Розвиваймося разом!!!",
  },
  {
    name: 'Тетяна Ліщук',
    text: 'Вибирали компанію по кількості відгуків, адже чим більше відгуків, тим кращий досвід. Задоволені на всі 100 %. Власники добросовісно ставляться до своєї роботи. Дизайн вражає, всі вимоги до вивіски враховані, якість відповідає ціні, дають рекомендації. Дуже дякую п. Софії за творчий підхід, за те, що любить свою роботу.Бажаємо Вам натхнення, здобутків, процвітання. ВИ РОБИТЕ НАШЕ МІСТО КРАСИВИМ!!! Однозначно рекомендуємо!!! За допомогою Вас, приємно і нам розвивати свою справу. УСПІХУ!!!',
  },
  {
    name: 'Таня Патрушева',
    text: "Замовляли багато реклами до відкриття, це і дрібнички всякі, і великі картини в середину, ну і звичайно сама реклама світяща. Персонал радив адекватні варіанти, щоб гарно і не дорого, і не загинали цін. Мені співпраця така сподобалась. Деколи бувало важко додзвонитись, казали роботи багато і не все зворотній зв'язок був, але то на термін виконання не вплинуло. Тому я задоволена і вам рекомендую",
  },
  {
    name: 'Тарас Вольфар',
    text: 'Замовляв вивіску. Чітко зрозуміли, що я хочу. Швидко розробили дизайн. Врахували всі поправки. Зробили дуже якісно і гарно. Сподобалось. Буду замовляти ще.',
  },
  {
    name: 'Андрій Мосорко',
    text: 'Найкращий вибір рекламних послуг саме тут! Завжди виконують замовлення швидко, вчасно і якісно. Дуже подобається відношення до клієнта: тут готові виконати термінові замовлення і без затримок. Тут чують кожного клієнта і завжди роблять так, щоб кожен їхній клієнт був задоволений послугами. Тут знають свою роботу і люди працюють на своїх місцях! Рекомендую!',
  },
  {
    name: 'Маргарита Абрамян',
    text: 'Сподобалось: Замовляли світлову вивіску, банери і перфоплівку. Всім залишилися дуже задоволені. Якість друку найвища. Працювалося дуже легко, так як вони чули нас і давали рекомендації як зробити краще. Процес створення дизайну вивіски ми всі запамятали адже так занурились в роботу що не помітили як пройшов час. А по результату рельність перевищила очікування. Рекомендуем. І будем ще звертатись за іншими видами реклами.',
  },
];

// ! Types
export const services = [
  {
    id: 1,
    background: orgklo,
    service: 'ВИВІСКИ З ОРГСКЛА (АКРИЛОВІ)',
    description:
      "Бувають рекламні (тобто в якості вивіски), номерні, настільні, вказівники та інші. У деяких випадках можна використовувати фасадну — пластикову табличку з об'ємними пластиковими літерами і логотипами. Кріпиться така вивіска як правило на дистанційні тримачі, що робить її ще акуратнішою і цікавою.",
    photos: [orgkloOne, orgkloTwo, orgkloThree, orgkloFour],
    photosSmall: [
      orgkloOneSmall,
      orgkloTwoSmall,
      orgkloThreeSmall,
      orgkloFourSmall,
    ],
  },

  {
    id: 2,
    background: lightLetters,
    service: "ОБ'ЄМНІ СВІТЛОВІ БУКВИ",
    description:
      "Об'ємні світлові літери, що світяться з лицевої сторони, мають добру проглядність як здалека, так і з невеликої відстані. Такі букви мають 7 - 8 см борт. Здебільшого роблять написи такого варіанту світлих кольорів, для більш яскравого підсвічування, або з невеликим контуром білого кольору спереду букви. Можуть бути як на рамі металевій, так і на основі будь-яких кольорів.",
    photos: [lightOne, lightTwo, lightThree, lightFour],
    photosSmall: [
      lightOneSmall,
      lightTwoSmall,
      lightThreeSmall,
      lightFourSmall,
    ],
  },
  {
    id: 3,
    background: notLightLetters,
    service: "ОБ'ЄМНІ НЕСВІТЛОВІ БУКВИ",
    description:
      "Один з найпоширеніших видів зовнішньої реклами. Завдяки своїй об'ємності мають ефектний вигляд та залишаються читабельними з різних ракурсів. Об'ємні букви можна монтувати на фасад або ж виготовити вивіску з об'ємними літерами. Така вивіска буде виглядати дуже цілісно і симпатично. приємця.",
    photos: [nonLightOne, nonLightTwo, nonLightThree, nonLightFour],
    photosSmall: [
      nonLightOneSmall,
      nonLightTwoSmall,
      nonLightThreeSmall,
      nonLightFourSmall,
    ],
  },
  {
    id: 4,
    background: box,
    service: 'БОКСИ',
    description:
      "Світлові короби – найбільш поширений вид зовнішньої реклами. Гарно та не дорого - це про нього. Крім того, при належній якості виконання, світловий короб ефектна, а також ефективна реклама, оскільки йому можна надати практично будь-якої форми, і він органічно вписується в будь-який екстер'єр, в тому числі і на дах будівлі. Можуть бути як односторонні так і ТОРЦЕВИКИ - двохсторонні",
    photos: [boxesNull, boxesOne, boxesTwo, boxesThree, boxesFour, boxesFive],
    photosSmall: [
      boxesNullSmall,
      boxesOneSmall,
      boxesTwoSmall,
      boxesThreeSmall,
      boxesFourSmall,
      boxesFiveSmall,
    ],
  },
  {
    id: 5,
    background: kronshtein,
    service: 'КРОНШТЕЙНИ (ТОРЦЕВИКИ)',
    description:
      'Вивіска кронштейн досить часто застосовується для розміщення на торці будинку, стовпі, або ж безпосередньо біля самого входу.',
    photos: [kronshteinOne, kronshteinTwo, kronshteinThree, kronshteinFour],
    photosSmall: [
      kronshteinOneSmall,
      kronshteinTwoSmall,
      kronshteinThreeSmall,
      kronshteinFourSmall,
    ],
  },
  {
    id: 6,
    background: stella,
    service: 'СТЕЛЛИ',
    description:
      "Стелла - рекламна конструкція, що встановлюється уздовж доріг, а також на великих площах, в якості вказівника. Може бути світловою, з об'ємними літерами або в самому простому варіанті. На ній розміщують необхідну інформацію про певний об'єкт, пропоновані товари чи послуги. Розташування вибирають неподалік від того місця, про яке йде мова, як правило зі стрілкою що вказуватиме напрямок до об'єкту.",
    photos: [
      stellaEleven,
      stellaTwelve,
      stellaThirteen,
      stellaOne,
      stellaTwo,
      stellaThree,
      stellaFour,
    ],
    photosSmall: [
      stellaElevenSmall,
      stellaTwelveSmall,
      stellaThirteenSmall,
      stellaOneSmall,
      stellaTwoSmall,
      stellaThreeSmall,
      stellaFourSmall,
    ],
  },
  {
    id: 7,
    background: flatLetters,
    service: 'ПЛОСКІ ЛІТЕРИ',
    description:
      'Композитні, акрилові, ПВХ. Можуть використовуватись як самостійно для оздоблення стіни, фотозони чи рецепції, так і для табличок.',
    photos: [flatNull, flatOne, flatTwo, flatFour, flatFive],
    photosSmall: [
      flatNullSmall,
      flatOneSmall,
      flatTwoSmall,
      flatFourSmall,
      flatFiveSmall,
    ],
  },
  {
    id: 8,
    background: bannerAds,
    service: 'БАНЕРНА РЕКЛАМА',
    description:
      'Банер - графічне зображення рекламного характеру. Банери розміщують для залучення потенційних клієнтів або для формування іміджу.',
    photos: [bannerAdOne, bannerAdTwo, bannerAdThree, bannerAdFour],
    photosSmall: [
      bannerAdOneSmall,
      bannerAdTwoSmall,
      bannerAdThreeSmall,
      bannerAdFourSmall,
    ],
  },
  {
    id: 9,
    background: contrazhur,
    service: 'КОНТРАЖУРНІ ЛІТЕРИ',
    description:
      'Літери з контражурною підсвіткою – один з найефектніших видів світлових зовнішніх вивісок. Ефект контражур створюється за допомогою правильно змонтованої світлодіодної стрічки, яка дає м’яку контурну підсвітку. Як правило літери повинні бути темними, а фон, тобто основа, світлим.',
    photos: [kontrazhurOne, kontrazhurTwo, kontrazhurThree, kontrazhurFour],
    photosSmall: [
      kontrazhurOneSmall,
      kontrazhurTwoSmall,
      kontrazhurThreeSmall,
      kontrazhurFourSmall,
    ],
  },
  {
    id: 10,
    background: stander,
    service: 'ШТЕНДЕРИ & СПОТИКАЧІ',
    description:
      'Штендер металевий — один з найефективніших видів реклами. Він може бути безпосередньою рекламою фірми або організації, а також давати інформацію про розпродаж, нові товари та знижки.',
    photos: [
      standerOne,
      standerTwo,
      standerThree,
      standerFive,
      standerFour,
      standerSeven,
      standerSix,
    ],
    photosSmall: [
      standerOneSmall,
      standerTwoSmall,
      standerThreeSmall,
      standerFiveSmall,
      standerFourSmall,
      standerSevenSmall,
      standerSixSmall,
    ],
  },
  {
    id: 11,
    background: city,
    service: 'ОРЕНДА СІТІЛАЙТІВ (КАЛИНОВА СЛОБОДА)',
    description:
      'Оренда сітілайтів у містечку "Калинова Слобода" у першій та другій черзі. Не світлові двохсторонні рекламні площини розміщені вздовж дороги та пішохідних зон містечка. Розміром реклами 130х90см (формат для друку самоклейка)',
    photos: [cityOne, cityTwo, cityThree],
    photosSmall: [cityOneSmall, cityTwoSmall, cityThreeSmall],
  },
  {
    id: 12,
    background: fotozone,
    service: 'ФОТОЗОНИ',
    description:
      "Фотозона - це яскрава і креативна конструкція, яка встановлюється на свята для створення фотографій, що запам'ятовуються. Фотозона на замовлення – це найкраще рішення для будь-якого свята. Вона може бути переносною або стаціонарною, світловою чи банерною.",
    photos: [fotozoneOne, fotozoneTwo, fotozoneThree],
    photosSmall: [fotozoneOneSmall, fotozoneTwoSmall, fotozoneThreeSmall],
  },
  {
    id: 13,
    background: branding,
    service: 'БРЕНДУВАННЯ',
    description:
      'Всі види транспорту — це потенційні рекламні носії, які дозволяють проводити нестандартні рекламні кампанії та створювати ефект “всюди сущності” Вашої реклами. Сучасний вид реклами, який користується популярністю серед рекламих агенств та привертає увагу споживачів.',
    photos: [brandingOne, brandingTwo, brandingFour],
    photosSmall: [brandingOneSmall, brandingTwoSmall, brandingFourSmall],
  },
  {
    id: 14,
    background: table,
    service: '1000 ДРІБНИЦЬ',
    description:
      'Настінні, настільні таблички, графіки роботи та багато іншого... Стисла подача інформації, чи довершення стилізації за допомогою дрібних деталей. Оформити інтер’єр офісу чи магазину – це все одно, що зробити ремонт будинку. На роботі ми проводимо більшу частину часу. В офісі знаходяться наші співробітники; в офіс приходять наші клієнти. Офіс, магазин, салон – це наш другий дім. І від того яким ми його зробимо, залежить наскільки часто ми захочемо в нього приходити. І наскільки часто в офіс будуть приходити наші клієнти. Ми робимо оформлення інтер’єру житлових та офісних приміщень.',
    photos: [
      tableOne,
      tableTwo,
      tableThree,
      tableFour,
      tableFive,
      tableSix,
      tableSeven,
      decorFour,
    ],
    photosSmall: [
      tableOneSmall,
      tableTwoSmall,
      tableThreeSmall,
      tableFourSmall,
      tableFiveSmall,
      tableSixSmall,
      tableSevenSmall,
      decorFourSmall,
    ],
  },
  {
    id: 15,
    background: metal,
    service: 'МЕТАЛЕВІ КОНСТРУКЦІЇ (ДАШКИ, ПЕРИЛА...)',
    description:
      'Дашки, перила, ґрати на вікна, лавочки тощо. Будь-які вироби під замовлення.',
    photos: [metalOne, metalTwo, metalThree, metalSix],
    photosSmall: [metalOneSmall, metalTwoSmall, metalThreeSmall, metalSixSmall],
  },
  {
    id: 16,
    background: remakingOld,
    service: 'ВІДНОВЛЕННЯ СТАРОЇ РЕКЛАМИ',
    description: '',
    photos: [remakingOne, remakingTwo, remakingThree],
    photosSmall: [remakingOneSmall, remakingTwoSmall, remakingThreeSmall],
  },
];

// ! Remaking old ads

export const oldAds = [
  {
    animation: cleaning,
    info: 'Очистка поверхонь від старих, зіпсованих плівок а також від плівок, що втратили свій товарний вигляд та поклейка нових, які також можна замовити та виготовити у нас',
  },
  {
    animation: repair,
    info: 'Ремонт та відновлення пластикових та металевих конструкцій(боксів, рам, каркасів, тощо...), підбір та заміна матеріалів.',
  },
  {
    animation: light,
    info: 'Роботи з світловими конструкціями(ремонт освітлення рекламних виробів, заміна несправних чи неефективних світлових елементів, заміна ламп денного світла на енергозберізаючі світлодіодні лампи).',
  },
  {
    animation: brush,
    info: 'Редизайн. Корекція вже існуючого дизайну, чи кардинальна його зміна з подальшим втіленням в життя.',
  },
];
