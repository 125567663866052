import styles from './old-ads.module.scss';
import { oldAds } from './../../infos/infos';
import { DotLottiePlayer } from '@dotlottie/react-player';
import React from 'react';

const OldAds = React.memo(() => {
  return (
    <div className={styles.oldAdsContainer}>
      {oldAds.map((ad) => (
        <div className={styles.oldAdContainer}>
          <div className={styles.animationContainer}>
            <DotLottiePlayer src={ad.animation} autoplay loop />
          </div>
          <p className={styles.info}>{ad.info}</p>
        </div>
      ))}
    </div>
  );
});

export default OldAds;
