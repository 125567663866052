import classnames from 'classnames';
import Logo from '../../elements/logo/Logo';
import Nav from '../nav/Nav';
import Social from '../social/Social';
import styles from './header.module.scss';
import NavVertical from '../nav-vertical/NavVertical';
import Icon from '../../elements/icon/Icon';
import React, { useState } from 'react';

const Header = () => {
  const [menu, setMenu] = useState(false);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.blur}></div>
      <div
        onClick={() => setMenu(false)}
        className={menu ? styles.navVertical : styles.navClose}
      >
        <NavVertical setMenu={setMenu} />
        <Social />
        <div onClick={() => setMenu(false)} className={styles.cross}>
          <Icon iconName='cross' />
        </div>
      </div>
      <div className={classnames(styles.container, styles.header)}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.nav}>
          <Nav />
        </div>
        <div className={styles.social}>
          <Social />
        </div>
        <div onClick={() => setMenu(!menu)} className={styles.burgerContainer}>
          <Icon iconName='burger' />
        </div>
      </div>
    </div>
  );
};

export default Header;
