import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import PortfolioPage from './pages/PortfolioPage';
import PassportPage from './pages/PassportPage';
import Policy from './pages/Policy';


const App = () => {

  return (
    <div>
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='/services/' element={<ServicesPage />}></Route>
        <Route
          path='/services/first-page/:id'
          element={<ServicesPage />}
        ></Route>
        <Route
          path='/services/second-page/:id'
          element={<ServicesPage />}
        ></Route>
        <Route path='/portfolio/' element={<PortfolioPage />}></Route>
        <Route path='/passport/' element={<PassportPage />}></Route>
        <Route path='/policy/' element={<Policy />}></Route>
      </Routes>
    </div>
  );
};

export default App;
