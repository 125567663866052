import classnames from 'classnames';
import Header from './../components/header/Header';
import styles from './services-page.module.scss';
import { services } from '../components/infos/infos';
import { HashLink, HashLink as Link } from 'react-router-hash-link';
import Icon from '../elements/icon/Icon';
import React, { useState } from 'react';
import Service from '../components/service-page/Service';
import Footer from '../components/footer/Footer';
import arrowDown from './../assets/animations/arrow-down.lottie';
import bg from './../assets/images/bg-3.png';
import ModalUp from './../components/modal-up/ModalUp';
import './openSlider.scss';

// Swiper
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import { DotLottiePlayer } from '@dotlottie/react-player';
import Catalog from '../components/catalog/Catalog';
import OpenImagesWrapper from '../components/open-images/OpenImagesWrapper';
import Title from '../elements/title/Title';
import ButtonsServices from '../components/buttons/ButtonsServices';
import { useNavigate } from 'react-router-dom';

const ServicesPage = React.memo(() => {
  const [closeSidebar, setCloseSidebar] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [activeSliderId, setActiveSliderId] = useState('');
  const [activeIndex, setActiveIndex] = useState('');
  const [line, setLine] = useState(false);
  const [showPage, setShowPage] = useState(1);

  const navigate = useNavigate();

  const handleHashLinkClick = (id) => {
    const page = id <= 8 ? 1 : 2;
    setShowPage(page);
    navigate(`/services/${page === 1 ? 'first-page' : 'second-page'}/${id}`);
  };

  return (
    <div id='services' className={styles.servicePageWrapper}>
      {openImg && (
        <OpenImagesWrapper
          setOpenImg={setOpenImg}
          list={services}
          activeIndex={activeIndex}
          activeSliderId={activeSliderId}
        />
      )}
      <ModalUp />
      <div
        className={
          closeSidebar ? styles.servicesWrapperOff : styles.servicesWrapper
        }
      >
        <Header />

        <div
          className={classnames(
            styles.containerServices,
            styles.contentContainer
          )}
        >
          <div
            className={classnames(styles.list, {
              [styles.listClose]: closeSidebar,
            })}
          >
            <div className={styles.content}>
              <div className={styles.links}>
                {services.map((service) => (
                  <div className={styles.service} key={service.service}>
                    <HashLink
                      smooth
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                        })
                      }
                      className={styles.link}
                      to={`#${service.id}`}
                      onClick={() => handleHashLinkClick(service.id)}
                    >
                      <span className={styles.text}>{service.service}</span>
                      <span className={styles.iconContainer}>
                        <Icon class={styles.iconArrow} iconName='arrow-right' />
                      </span>
                    </HashLink>
                    <div className={styles.line}></div>
                  </div>
                ))}
              </div>
            </div>
            <div
              onClick={() => setCloseSidebar(!closeSidebar)}
              className={styles.buttonContainer}
            >
              <div className={styles.button}>
                {closeSidebar ? (
                  <Icon iconName='wide-arrow' class={styles.icon} />
                ) : (
                  <Icon iconName='arrow-wide-left' class={styles.icon} />
                )}
              </div>
            </div>
          </div>
          <div className={classnames(styles.container, styles.services)}>
            <Title variant='page' id='title' text='НАШІ ПОСЛУГИ' />
            <Title
              variant='subtitleSmall'
              text='ТУТ ВИ ЗНАЙДЕТЕ НАШІ РОБОТИ:'
            />
            <HashLink
              smooth
              scroll={(el) =>
                el.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
              to={showPage === 1 ? `#${services[0].id}` : `#${services[8].id}`}
              className={styles.arrow}
            >
              <DotLottiePlayer src={arrowDown} autoplay loop />
            </HashLink>

            <Catalog setShowPage={setShowPage} line={line} setLine={setLine} />

            <div
              className={
                line ? styles.catalogueLineActive : styles.catalogueLine
              }
            ></div>
            <ButtonsServices showPage={showPage} setShowPage={setShowPage} />

            <Service
              page={showPage}
              setOpenImg={setOpenImg}
              openImg={openImg}
              setActiveSliderId={setActiveSliderId}
              setActiveIndex={setActiveIndex}
            />
          </div>
        </div>
        <ButtonsServices showPage={showPage} setShowPage={setShowPage} />
        <div className={styles.imgContainer}>
          <img src={bg} alt='block background image' />
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </div>
  );
});

export default ServicesPage;