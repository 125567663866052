import classnames from 'classnames';
import styles from './footer.module.scss';
import Logo from './../../elements/logo/Logo';
import Social from '../social/Social';
import NavVertical from '../nav-vertical/NavVertical';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Footer = React.memo(() => {
  return (
    <footer id='contacts' className={styles.footerWrapper}>
      <div className={classnames(styles.container, styles.footer)}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.navContainer}>
          <h4 className={classnames(styles.footerTitle, styles.menu)}>Меню</h4>
          <NavVertical />
        </div>
        <div className={styles.contactsContainer}>
          <h4 className={styles.footerTitle}>Контакти</h4>
          <div className={styles.contacts}>
            <div className={styles.flexContainer}>
              <h5 className={styles.smallTitle}>Телефони:</h5>
              <div className={styles.numberContainer}>
                <span className={styles.number}>(066) 46-799-95</span>
                <span className={styles.number}>(068) 28-102-14</span>
              </div>
            </div>
            <div className={styles.flexContainer}>
              <h5 className={styles.smallTitle}>E-mails:</h5>
              <div className={styles.numberContainer}>
                <span className={styles.number}>reklama.bmng@gmail.com</span>
                <span className={styles.number}>bmng.info.ra@gmail.com</span>
              </div>
            </div>
            <div className={styles.flexContainer}>
              <h5 className={styles.smallTitle}>Офіс:</h5>
              <div className={styles.numberContainer}>
                <span className={styles.number}>
                  вул. Тарнавського 1 (на куті Василіянок і Дністровської)
                </span>
              </div>
            </div>
          </div>
          <HashLink smooth className={styles.policyLink} to='/policy/#top'>
            Політика конфіденційності
          </HashLink>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
