import styles from './catalog.module.scss';
import { services } from '../infos/infos';
import Icon from '../../elements/icon/Icon';
import { HashLink } from 'react-router-hash-link';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Catalog = React.memo((props) => {
  const [active, setActive] = useState(false);
    const navigate = useNavigate();

    const handleHashLinkClick = (id) => {
      const page = id <= 8 ? 1 : 2;
      props.setShowPage(page);
      navigate(`/services/${page === 1 ? 'first-page' : 'second-page'}/${id}`);
    };

  return (
    <div>
      <button
        onClick={() => {
          setActive(!active);
          props.setLine(!props.line);
        }}
        className={styles.button}
      >
        Каталог
      </button>
      <div className={active ? styles.contentActive : styles.content}>
        <div className={styles.links}>
          {services.map((service) => (
            <div className={styles.service} key={service.service}>
              <HashLink
                smooth
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
                className={styles.link}
                to={`#${service.id}`}
                onClick={() => handleHashLinkClick(service.id)}
              >
                <span className={styles.text}>{service.service}</span>
                <span className={styles.iconContainer}>
                  <Icon class={styles.iconArrow} iconName='arrow-right' />
                </span>
              </HashLink>
              <div className={styles.line}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Catalog;
