import React, { lazy, Suspense } from 'react';
import Header from './../components/header/Header';
import ModalUp from './../components/modal-up/ModalUp';
import CookieConsent from 'react-cookie-consent';
import styles from './home-page.module.scss';
import Logo from '../elements/logo/Logo';
import { HashLink } from 'react-router-hash-link';
const Steps = lazy(() => import('./../components/steps/Steps'));
const Reviews = lazy(() => import('./../components/reviews/Reviews'));
const Advantages = lazy(() => import('./../components/advantages/Advantages'));
const Passport = lazy(() => import('./../components/passport/Passport'));
const Footer = lazy(() => import('./../components/footer/Footer'));
const MainServices = lazy(() =>
  import('../components/main-services/MainServices')
);

const HomePage = () => {
  return (
    <>
      <Suspense
        fallback={
          <div className={styles.loading}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </div>
        }
      >
        <ModalUp />
        <Header />
        <MainServices />
        <Steps />
        <Passport />
        <Advantages />

        <Reviews />
        <Footer />
      </Suspense>
      <CookieConsent
        location='bottom'
        buttonText='Зрозуміло'
        cookieName='myCookie'
        className={styles.cookie}
        style={{
          background: 'rgba(1, 1, 1, 0.902)',
          padding: '8px 10px',
          fontSize: '14px',
        }}
        buttonStyle={{
          color: '#fff',
          fontSize: '16px',
          background: '#c60c30',
          padding: '10px 15px',
          borderRadius: '15px',
        }}
        expires={150}
      >
        <div className={styles.cookie}>
          Ми використовуємо файли cookie, необхідні для коректної роботи сайту.
          Натискаючи на кнопку 'Зрозуміло' Ви погоджуєтеся із використанням
          даних технологій. Для більш детальної інформації перейдіть за
          посиланням <span> </span>
          <HashLink
            smooth
            style={{
              color: '#fff',
              borderBottom: '1px solid #fff',
              width: 'fit-content',
            }}
            to='/policy/#top'
          >
            'Політика конфіденційності'.
          </HashLink>
        </div>
      </CookieConsent>
    </>
  );
};

export default HomePage;
