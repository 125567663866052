import React, { lazy, Suspense } from 'react';
import classNames from 'classnames';
import styles from './policy.module.scss';
import Header from '../components/header/Header';
import { HashLink } from 'react-router-hash-link';
import ModalUp from '../components/modal-up/ModalUp';
import bg from './../assets/images/bg-3.png';

const Footer = lazy(() => import('../components/footer/Footer'));

const Policy = React.memo(() => {
  return (
    <div id='policy'>
      <ModalUp />
      <div>
        <Header />
        <div className={classNames(styles.container, styles.policyPage)}>
          <div className={styles.descriptionContainer}>
            <h2 id='title' className={styles.title}>
              ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ТА ФАЙЛИ COOKIE
            </h2>

            <p className={styles.description}>
              Конфіденційність відвідувачів нашого веб-сайту
              <a className={styles.link} href='https://boomerang-ra.com/'>
                https://boomerang-ra.com
              </a>
              дуже важлива для нас, і ми прагнемо її захищати. Ця політика
              конфіденційності та файли cookie (надалі – «Політика») пояснює, що
              ми будемо робити з вашою особистою інформацією. Ця Політика
              поширюється тільки на інформацію, зібрану на нашому веб-сайті
              <a className={styles.link} href='https://boomerang-ra.com/'>
                https://boomerang-ra.com
              </a>
              , тому ми не несемо відповідальності за політику конфіденційності
              або зміст веб-сайтів третіх сторін.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>1. ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>

            <p className={styles.description}>
              1.1. «Веб-сайт» (надалі – «Сайт») – інформаційний ресурс в мережі
              Інтернет, який розміщений за адресою https://boomerang-ra.com та
              містить текстову, графічну та іншу інформацію, яка сприймається як
              єдине ціле, та підтримується комплексом системних програмних
              засобів.
            </p>
            <p className={styles.description}>
              1.2. «Власник Сайту» (надалі – «Власник») – Товариство з обмеженою
              відповідальністю «БУМЕРАНГ», який є володільцем та розпорядником
              персональних даних Користувачів Сайту.
            </p>
            <p className={styles.description}>
              1.3. «Користувач» – будь-яка фізична особа, яка коли-небудь
              здійснювала доступ до Сайту та досягла віку, допустимого для
              акцепту Угоди користувача розміщеної на Сайті та цієї Політики.
            </p>
            <p className={styles.description}>
              1.4. «Персональні дані» – відомості чи сукупність відомостей про
              Користувача, яка ідентифікована або може бути конкретно
              ідентифікована.
            </p>
            <p className={styles.description}>
              1.5. «Послуги» (сервіси Сайту) – будь-які послуги, які
              пропонуються на Сайті, а саме послуги створення будь-яких видів
              реклами.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>
              2. ЗБІР ТА ВИКОРИСТАННЯ ПЕРСОНАЛЬНИ ДАНИХ
            </h2>
            <p className={styles.description}>
              2.1. Використання Сайту не вимагає реєстрації та/або авторизації
              та безпосереднього надання персональних даних, які можуть
              ідентифікувати Вас.
            </p>
            <p className={styles.description}>
              2.2. При використанні Сайту ми можемо здійснювати автоматичний
              збір інформації, пов’язаної із використанням Вами Сайту.
            </p>
            <p className={styles.description}>
              2.3. Ми можемо збирати, зберігати та використовувати такі типи
              Ваших персональних даних:
            </p>
            <p className={styles.description}>
              2.3.1. інформацію про Ваш комп'ютер, включаючи IP-адресу,
              географічне розташування, тип і версію браузера та операційну
              систему;
            </p>
            <p className={styles.description}>
              2.3.2. інформацію про Ваші відвідування та використання цього
              Сайту, включаючи джерело рефералів, тривалість відвідування,
              перегляд сторінок та маршрути навігації на Сайті;
            </p>
            <p className={styles.description}>
              2.3.3. інформацію, яка генерується під час використання нашого
              Сайту, у тому числі коли, як часто та за яких обставин Ви його
              використовуєте;
            </p>
            <p className={styles.description}>
              2.4. У випадку коли Ви відвідуєте Сайт встановлюється з’єднання,
              здійснюється доступ до даних на Сайті і створюється log-файл. Він
              може містити:
            </p>
            <div className={styles.items}>
              <p className={styles.description}>
                – дату і час доступу до Сайту;
              </p>
              <p className={styles.description}>
                – IP-адресу, з якої здійснювався доступ до Сайту;
              </p>
              <p className={styles.description}>
                – дані або ім’я файлу, до якого Ви звернулися на Сайті;
              </p>
              <p className={styles.description}>
                – кількість часу, проведеного на Сайті;
              </p>
              <p className={styles.description}>
                – назву і версію веб-браузера, Вашої операційної системи;
              </p>
              <p className={styles.description}>
                – назву веб-сайту, з якого Ви перейшли на Сайт.
              </p>
            </div>
            <p className={styles.description}>
              Ця інформація використовується для покращення продуктивності
              роботи Сайту, з міркувань безпеки, для забезпечення стабільності
              роботи Сайту, а також для захисту Сайту від можливих зовнішніх
              атак. Окрім цього, ми можемо здійснювати статистичний аналіз цих
              даних. Дані, що зберігаються у log-файлі не дають можливості нам
              ідентифікувати Вашу особу. Дані у log-файлі використовуються лише
              для мети вказаної у цій Політиці і не передаються третім сторонам.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>
              3. МЕТА ВИКОРИСТАННЯ ПЕРСОНАЛЬНИХ ДАНИХ
            </h2>
            <p className={styles.description}>
              3.1. Ми можемо використовувати Вашу персональну інформацію з
              метою:
            </p>
            <p className={styles.description}>
              3.1.1. надання третім сторонам статистичної інформації про наших
              користувачів (при цьому треті сторони не зможуть ідентифікувати
              жодного окремого користувача з цієї інформації);
            </p>
            <p className={styles.description}>
              3.1.2. збереження нашого Сайту в безпеці та попередження
              шахрайства;
            </p>
            <p className={styles.description}>
              3.2. Якщо Ви виявили бажання надати персональну інформацію для
              публікації на нашому Сайті, ми опублікуємо та іншим чином
              використаємо цю інформацію відповідно до дозволу та/або ліцензії,
              які Ви надаєте нам.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>
              4. ТЕРМІН ЗБЕРІГАННЯ ПЕРСОНАЛЬНИХ ДАНИХ
            </h2>
            <p className={styles.description}>
              4.1. Персональні дані зберігаються на термін не більше, ніж це
              необхідно відповідно до мети їх обробки.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>
              5. ВЗАЄМОДІЯ САЙТУ З ІНШИМИ РЕСУРСАМИ
            </h2>
            <p className={styles.description}>
              5.1. При використанні Вами сервісів, на сторінках Сайту можуть
              бути присутні коди інших інтернет ресурсів і третіх сторін, в
              результаті чого такі інтернет ресурси і треті сторони отримують
              Ваші дані. Ці інтернет-ресурси можуть отримувати і обробляти
              інформацію, про те, що Ви відвідали ці сторінки, а також іншу
              інформацію, яку передає Ваш браузер. Такими інтернет-ресурсами
              можуть бути системи банеропоказів.
            </p>
            <p className={styles.description}>
              5.2. Використання зазначених сервісів необхідно для оперативного
              аналізу відвідувань Сайту, внутрішньої і зовнішньої оцінки
              відвідуваності Сайту, глибини переглядів, активності Користувачів.
              Дані, отримані від зазначених сервісів ми не зберігаємо і не
              обробляємо.
            </p>
            <p className={styles.description}>
              5.3. Якщо Ви в силу будь-яких причин не бажаєте, щоб сервіси
              передбачені у п. 5.1. Політики отримували доступ до Ваших
              персональних даних, Ви можете за власним бажанням очистити файли
              cookie (через свій браузер).
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>
              6. ВЗАЄМОДІЯ ВЛАСНИКА З ТРЕТІМИ СТОРОНАМИ СТОСОВНО ПЕРСОНАЛЬНИХ
              ДАНИХ
            </h2>
            <p className={styles.description}>
              6.1. Ми не здійснюємо передачу персональних даних третім сторонам,
              крім випадків, коли така передача є вимогою чинного законодавства
              України, на прохання суб'єкта персональних даних або в інших
              випадках, викладених в цій Політиці. Ми розуміємо, що особиста
              інформація є цінністю і невід'ємним змістом, в тому числі,
              особистих немайнових прав будь-якої фізичної особи, тому вживаємо
              всіх можливих заходів для захисту Вашої особистої інформації.
            </p>
            <p className={styles.description}>
              6.2. Доступ до Ваших персональних даних можуть мати наші
              уповноважені працівники, які надали згоду на забезпечення
              конфіденційності персональних даних Користувачів, а також треті
              сторони, які надають нам послуги з підтримки і адміністрування
              Сайту, інші послуги, пов’язані із здійсненням Власником
              господарської діяльності. Наші уповноважені працівники та/або
              треті сторони на підставі укладених з нами договорів
              зобов’язуються дотримуватись усіх вимог чинного законодавства
              України щодо захисту персональних даних Користувачів та їх
              конфіденційності.
            </p>
            <p className={styles.description}>
              6.3. На Сайті можуть бути посилання на інші веб-сайти (виключно в
              інформаційних цілях). При переході по посиланню на інші веб-сайти
              дія цієї Політики на такі сайти поширюватися не буде. У зв'язку з
              чим, ми рекомендуємо Вам переглядати політику в сфері
              конфіденційності і персональних даних кожного веб-сайту перед тим,
              як передавати будь-які персональні дані, за якими Вас можуть
              ідентифікувати.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>7. ЗАХИСТ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
            <p className={styles.description}>
              7.1. Ми використовуємо загальноприйняті стандарти технологічного
              та операційного захисту інформації та персональних даних від
              втрати, неправильного використання, зміни або знищення, але ми не
              гарантуємо абсолютну захищеність від будь-яких загроз, що
              виникають поза межами нашого регулювання.
            </p>
            <p className={styles.description}>
              7.2. Ми забезпечуємо застосування всіх відповідних зобов'язань
              щодо дотримання конфіденційності, а також технічних і
              організаційних заходів безпеки для запобігання несанкціонованого
              або незаконного розголошення або обробки такої інформації та
              даних.
            </p>
            <p className={styles.description}>
              7.3. Ми маємо право поширювати персональні дані без Вашої згоди у
              випадку визначеному чинним законодавством України, і лише (якщо це
              необхідно) в інтересах національної безпеки, економічного
              добробуту та прав людини.
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>
              8. ВАШІ ПРАВА, ЯК КОРИСТУВАЧА (СУБ’ЄКТА ПЕРСОНАЛЬНИХ ДАНИХ)
            </h2>
            <p className={styles.description}>
              8.1. Ви, як Користувач (суб’єкт персональних даних) маєте право:
            </p>
            <p className={styles.description}>
              1) знати про джерела збирання, місцезнаходження своїх персональних
              даних, мету їх обробки, місцезнаходження Власника або дати
              відповідне доручення щодо отримання цієї інформації уповноваженим
              особам, крім випадків, встановлених Законом України «Про захист
              персональних даних»;
            </p>
            <p className={styles.description}>
              2) отримувати інформацію про умови надання доступу до персональних
              даних, зокрема інформацію про третіх сторін, яким передаються його
              персональні дані;
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>9. ФАЙЛИ COOKIE</h2>
            <p className={styles.description}>
              9.1. Наш Сайт використовує файли cookie. Файл cookie – це файл, що
              містить ідентифікатор (рядок літер та цифр), який веб-сервер
              надсилає веб-браузеру та зберігає браузер. Потім ідентифікатор
              надсилається назад на сервер кожного разу, коли браузер вимагає
              від сервера сторінки. Файли cookie можуть бути як «стійкими»
              файлами cookie, так і «сеансовими» файлами cookie: стійкий файл
              cookie буде зберігатися веб-браузером і залишатиметься дійсним до
              встановленої дати його закінчення, якщо Ви його не видалили до
              дати закінчення терміну дії; cookie сеанс, з іншого боку,
              закінчується в кінці сеансу Вашої роботи, коли веб-браузер
              закриється. Файли cookie, як правило, не містять будь-якої
              інформації, яка особисто Вас ідентифікує, але особиста інформація,
              яку ми зберігаємо про Вас, може бути пов’язана з інформацією, що
              зберігається та отримується від файлів cookie.
            </p>
            <p className={styles.description}>
              9.2. Назви файлів cookie, які ми використовуємо на нашому Сайті,
              та цілі, для яких вони використовуються, викладені нижче: ми
              використовуємо Google Analytics на нашому Сайті для розпізнавання
              комп'ютера, коли Користувач відвідує наш Сайт.
            </p>
            <p className={styles.description}>
              Більшість браузерів дозволяють Вам відмовитись від прийняття
              файлів cookie – наприклад: в Internet Explorer (версія 10) Ви
              можете заблокувати файли cookie, використовуючи параметри
              керування файлами cookie, натиснувши «Інструменти», «Параметри
              Інтернету», «Конфіденційність», а потім «Додатково»;
            </p>
            <p className={styles.description}>
              у Firefox (версія 24) Ви можете заблокувати всі файли cookie,
              натиснувши «Інструменти», «Параметри», «Конфіденційність»,
              виберіть зі спадного меню пункт «Використовувати власні
              налаштування для історії» та зніміть галочку «Прийняти файли
              cookie з сайтів»;
            </p>
            <p className={styles.description}>
              і у Chrome (версія 29) Ви можете заблокувати всі файли cookie,
              відкривши меню «Налаштувати та керувати», натиснувши
              «Налаштування», «Показати розширені налаштування» та «Налаштування
              вмісту», а потім вибрати «Блокувати сайти з налаштування будь-яких
              даних» «Під заголовком» Cookies.
            </p>
            <p className={styles.description}>
              9.3. Блокування всіх файлів cookie негативно вплине на зручність
              використання Сайту.{' '}
            </p>
            <p className={styles.description}>
              9.4. Ви можете видалити файли cookie, які вже зберігаються на
              вашому комп'ютері. Видалення файлів cookie негативно вплине на
              зручність використання багатьох веб-сайтів.
            </p>
            <p className={styles.description}>Для видалення файлів cookie:</p>
            <p className={styles.description}>
              в Internet Explorer (версія 10) потрібно видалити файли cookie
              вручну (інструкції щодо цього можна знайти на веб-сайті
              http://support.microsoft.com/kb/278835);
            </p>
            <p className={styles.description}>
              у Firefox (версія 24) Ви можете видалити файли cookie, натиснувши
              «Інструменти», «Параметри» та «Конфіденційність», потім виберіть
              «Використовувати власні налаштування для історії», натисніть
              «Показати файли cookie», а потім натисніть «Видалити всі файли
              cookie»;
            </p>
            <p className={styles.description}>
              і у Chrome (версія 29) Ви можете видалити всі файли cookie,
              відкривши меню «Налаштувати та керувати» та натиснувши
              «Налаштування», «Показати розширені налаштування» та «Очистити
              дані веб-перегляду», а потім вибрати «Видалити файли cookie та
              інший сайт і плагінні дані», перш ніж натиснути «Очистити дані
              веб-перегляду».
            </p>
          </div>
          <div className={styles.descriptionContainer}>
            <h2 className={styles.title}>10. ЗМІНА ПОЛІТИКИ</h2>
            <p className={styles.description}>
              10.1. Ми залишаємо за собою право публікувати окремі примітки про
              конфіденційність, а також у будь-який час змінювати та/або
              доповнювати зміст Політики на власний розсуд.
            </p>
            <p className={styles.description}>
              10.2. У випадку внесення істотних змін до Політики ми розмістимо
              повідомлення на Сайті та зазначено термін набрання цими змінами
              чинності. Якщо протягом зазначеного терміну Ви не відмовитеся від
              прийняття цих змін в письмовій формі, це означатиме, що Ви
              погодились з відповідними змінами Політики.
            </p>
          </div>
        </div>
        <div className={styles.imgContainerTwo}>
          <img src={bg} alt='block background image' />
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
});

export default Policy;
