import React, { lazy, Suspense } from 'react';
import Header from '../components/header/Header';
import styles from './portfolioPage.module.scss';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import arrowDown from './../assets/animations/arrow-down.lottie';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from 'react-zoom-pan-pinch';
import { useState } from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import bg from './../assets/images/bg-3.png';
import ModalUp from '../components/modal-up/ModalUp';
import Icon from '../elements/icon/Icon';
import Title from '../elements/title/Title';

import ImagesList from '../components/images-list/ImagesList';
import Buttons from '../components/buttons/Buttons';
import { images } from './../components/infos/infos';

const Footer = lazy(() => import('../components/footer/Footer'));

const PortfolioPage = React.memo(() => {
  const [openImg, setOpenImg] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [showPage, setShowPage] = useState(1);

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className={styles.controlsContainer}>
        <button
          className={styles.controlButton}
          onClick={(e) => {
            zoomIn();
            e.stopPropagation();
          }}
        >
          <span className={styles.big}>Збільшити</span>
          <Icon class={styles.small} iconName='expand' />
        </button>
        <button
          className={styles.controlButton}
          onClick={(e) => {
            zoomOut();
            e.stopPropagation();
          }}
        >
          <span className={styles.big}>Зменшити</span>
          <Icon class={styles.small} iconName='collapse' />
        </button>
        <button
          className={styles.controlButton}
          onClick={(e) => {
            resetTransform();
            e.stopPropagation();
          }}
        >
          <span className={styles.big}>Скинути</span>
          <Icon class={styles.small} iconName='restart' />
        </button>
      </div>
    );
  };

  const selectedImage = images[imgIndex] || {};

  return (
    <div id='portfolio' className={styles.portfolioPageWrapper}>
      <ModalUp />
      <Header />
      <div className={styles.portfolioWrapper}>
        <div className={classNames(styles.container, styles.services)}>
          <Title variant='page' id='title' text='НАШЕ ПОРТФОЛІО' />
          <Title
            variant='subtitleSmall'
            text='ТУТ ВИ ЗНАЙДЕТЕ ПРИКЛАДИ НАШИХ РОБІТ:'
          />
          <HashLink smooth to='#imagesContainer' className={styles.arrow}>
            <DotLottiePlayer src={arrowDown} autoplay loop />
          </HashLink>
        </div>
        <Buttons setShowPage={setShowPage} />
        {!openImg && (
          <div id='imagesContainer' className={styles.imagesContainer}>
            <ImagesList
              page={
                showPage === 1
                  ? 1
                  : showPage === 2
                  ? 2
                  : showPage === 3
                  ? 3
                  : showPage === 4
                  ? 4
                  : showPage === 5
                  ? 5
                  : ''
              }
              setOpenImg={setOpenImg}
              setImgIndex={setImgIndex}
            />
          </div>
        )}
        <Buttons setShowPage={setShowPage} />;
        {openImg && (
          <div
            className={styles.openImagesContainer}
            onClick={() => setOpenImg(false)}
          >
            <TransformWrapper>
              <TransformComponent>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={styles.openImageContainer}
                >
                  <img src={selectedImage.image} />
                </div>
              </TransformComponent>
              <Controls />
            </TransformWrapper>
            <div className={styles.crossContainer}>
              <Icon iconName='cross' />
            </div>
          </div>
        )}
      </div>
      <div className={styles.imgContainerTwo}>
        <img src={bg} alt='block background image' />
      </div>
      <div className={styles.footerWrapper}>
        <Suspense fallback={<div>Loading...</div>}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
});

export default PortfolioPage;
