import { Link } from 'react-router-dom';
import logo from './../../assets/logo.png';
import styles from './logo.module.scss';
import { HashLink } from 'react-router-hash-link';
import React from 'react';

const Logo = React.memo(() => {
  return (
    <HashLink smooth className={styles.logo} to='/#top'>
      <img className={styles.img} src={logo} alt='Logo' />
    </HashLink>
  );
});

export default Logo;
