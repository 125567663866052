import styles from './service.module.scss';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.scss';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import OldAds from './oldAds/OldAds';
import { services } from '../infos/infos'; // Імпорт services
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';


const Service = React.memo((props) => {
  const filteredServices = services.filter((service) => {
    if (props.page === 1) {
      return service.id <= 8;
    } else if (props.page === 2) {
      return service.id > 8; // Змінено умову на service.id > 8
    }
    return true;
  });

  return (
    <>
      {filteredServices.map(
        (
          filteredService // Використання filteredService замість filteredArray
        ) => (
          <div
            key={filteredService.id} // Додано ключ
            id={filteredService.id}
            className={
              filteredService.description === ''
                ? styles.noDesc
                : filteredService.id % 2 === 0
                ? styles.serviceReverse
                : styles.service
            }
          >
            <div
              className={
                filteredService.description === ''
                  ? styles.noDescImages
                  : styles.images
              }
            >
              <Swiper
                spaceBetween={20}
                slidesPerView={2}
                modules={[Navigation, Pagination, A11y, Autoplay]}
                breakpoints={{
                  1300: { slidesPerView: 2 },
                  768: { slidesPerView: 2 },
                  0: { slidesPerView: 1 },
                }}
                speed={
                  filteredService.description === ''
                    ? 600
                    : filteredService.id % 2 === 0
                    ? 500
                    : 400
                }
                loop={true}
                navigation
                autoplay={true}
                className='service'
              >
                {filteredService.photosSmall &&
                  filteredService.photosSmall.map((photo, index) => (
                    <SwiperSlide key={index} className={styles.swiperSlide}>
                      <img
                        className={styles.image}
                        src={photo}
                        alt={`Service ${filteredService.id} Photo`}
                        onClick={() => {
                          props.setActiveSliderId(filteredService.id);
                          props.setOpenImg(!props.openImg);
                          props.setActiveIndex(index);
                        }}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div
                className={
                  filteredService.description === ''
                    ? styles.noDescInfos
                    : styles.infosContainer
                }
              >
                <div className={styles.numberContainer}>
                  <div className={styles.circle}>
                    <span className={styles.number}>{filteredService.id}</span>
                  </div>
                  <h2 className={styles.title}>{filteredService.service}</h2>
                </div>
                <p className={styles.description}>
                  {filteredService.description}
                </p>
                {filteredService.id === 16 && <OldAds />}
              </div>
            </div>
          </div>
        )
      )}
      <div className={styles.line}></div>
    </>
  );
});

export default Service;
