import classnames from 'classnames';
import icons from './../../assets/svg/symbol-defs.svg';
import './icon.scss';
import React from 'react';

const Icon = React.memo((props) => {
  return (
    <svg
      className={classnames(`icon icon-${props.iconName}`, props.class)}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <use xlinkHref={`${icons}#icon-${props.iconName}`}></use>
    </svg>
  );
})

export default Icon;