import '../../styles/index.scss';
import styles from './modal.module.scss';
import { HashLink as Link } from 'react-router-hash-link';
import React, { useState, useEffect } from 'react';
import arrowModal from './../../assets/animations/arrow-modal.lottie';
import { DotLottiePlayer } from '@dotlottie/react-player';

const ModalUp = React.memo(() => {
  const [isVisible, setIsVisible] = useState(false); // початкове значення кнопки - її не видно
  const [height, setHeight] = useState(0); // початкове значення висоти 0

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll); // додаємо івент - коли починаємо скролити, виконується функція listenToScroll
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToShowFrom = 0;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);
    // console.log(winScroll);

    if (winScroll > heightToShowFrom) {
      !isVisible && setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div className='container'>
      {isVisible && (
        <Link smooth className={styles.link} to='#top'>
          {/* <Lottie animationData={arrowModal} /> */}
          <DotLottiePlayer src={arrowModal} autoplay loop />
        </Link>
      )}
    </div>
  );
});

export default ModalUp;
