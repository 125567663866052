import React from 'react';
import styles from './images-list.module.scss';
import { images } from './../infos/infos';

const firstPageList = images.filter((image) => image.id <= 30);
const secondPageList = images.filter(
  (image) => image.id > 30 && image.id <= 60
);
const thirdPageList = images.filter((image) => image.id > 60 && image.id <= 90);
const forthPageList = images.filter(
  (image) => image.id > 90 && image.id <= 120
);
const fifthPageList = images.filter((image) => image.id > 120);

const ImagesList = ({ setOpenImg, setImgIndex, page }) => {
  const selectedPageList =
    page === 1
      ? firstPageList
      : page === 2
      ? secondPageList
      : page === 3
      ? thirdPageList
      : page === 4
      ? forthPageList
      : page === 5
      ? fifthPageList
      : [];

  return (
    <div className={styles.imagesContainer}>
      {selectedPageList.map((item, index) => (
        <div className={styles.images}>
          <div
            onClick={() => {
              setOpenImg(true);
              setImgIndex(item.id - 1);
            }}
            className={styles.imgContainer}
          >
            <img src={item.imageSmall} />
          </div>
          <div className={styles.line}></div>
        </div>
      ))}
    </div>
  );
};

export default ImagesList;
