import React from 'react';
import styles from './buttons.module.scss';
import { HashLink } from 'react-router-hash-link';

const Buttons = ({ setShowPage }) => {
  return (
    <div className={styles.buttons}>
      <HashLink
        to='/portfolio/#top'
        smooth
        className={styles.pageButton}
        onClick={() => setShowPage(1)}
      >
        1
      </HashLink>
      <HashLink
        to='/portfolio/#top'
        smooth
        className={styles.pageButton}
        onClick={() => setShowPage(2)}
      >
        2
      </HashLink>
      <HashLink
        to='/portfolio/#top'
        smooth
        className={styles.pageButton}
        onClick={() => setShowPage(3)}
      >
        3
      </HashLink>
      <HashLink
        to='/portfolio/#top'
        smooth
        className={styles.pageButton}
        onClick={() => setShowPage(4)}
      >
        4
      </HashLink>
      <HashLink
        to='/portfolio/#top'
        smooth
        className={styles.pageButton}
        onClick={() => setShowPage(5)}
      >
        5
      </HashLink>
    </div>
  );
};

export default Buttons;
