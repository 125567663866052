import { HashLink } from 'react-router-hash-link';
import styles from './nav.module.scss';
import React from 'react';

const Nav = React.memo(() => {
  return (
    <nav className={styles.nav}>
      <HashLink smooth className={styles.link} to='/#top'>
        Головна
      </HashLink>
      <HashLink smooth className={styles.link} to='/services#title'>
        Послуги
      </HashLink>
      <HashLink smooth className={styles.link} to='/portfolio#top'>
        Портфоліо
      </HashLink>
      <HashLink smooth className={styles.link} to='/#reviews'>
        Відгуки
      </HashLink>
      <HashLink smooth className={styles.link} to='#contacts'>
        Контакти
      </HashLink>
    </nav>
  );
});

export default Nav;
