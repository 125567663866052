import React, { lazy, Suspense } from 'react';
import classNames from 'classnames';
import styles from './passport-page.module.scss';
import Header from '../components/header/Header';
import { HashLink } from 'react-router-hash-link';
import arrowDown from './../assets/animations/arrow-down.lottie';
import passport from './../assets/images/passport-2.jpg';
import worker from './../assets/animations/worker.lottie';
import { DotLottiePlayer } from '@dotlottie/react-player';
import ModalUp from '../components/modal-up/ModalUp';
import bg from './../assets/images/bg-3.png';

const Footer = lazy(() => import('../components/footer/Footer'));

const PassportPage = React.memo(() => {
  return (
    <div className={styles.passportPageWrapper}>
      <ModalUp />
      <div className={styles.bg}>
        <Header />
        <div className={classNames(styles.container, styles.passportPage)}>
          <h2 id='title' className={styles.title}>
            Паспорт вивіски
          </h2>
          <h3 className={styles.subtitle}>Що це таке?</h3>
          <HashLink smooth to='#contentContainer' className={styles.arrow}>
            <DotLottiePlayer src={arrowDown} autoplay loop />
          </HashLink>
          <div id='contentContainer' className={styles.contentContainer}>
            <div className={styles.passportContainer}>
              <div className={styles.descriptionContainer}>
                <p className={styles.description}>
                  <span>ПАСПОРТ ВИВІСКИ</span> - це документ А3 в трьох
                  примірниках, які Вам ще потрібно погодити в ЦНАПі. Можливе
                  замовлення на вже існуючу у вас рекламу або на нову, яку
                  виготовлятимемо ми під вимоги.
                </p>
                <div className={styles.priceContainer}>
                  Ціна документа в межах 600-1000грн.
                </div>
              </div>
              <div className={styles.imageContainer}>
                <img src={passport} alt='Passport' />
              </div>
            </div>
            <div className={styles.additionalFunction}>
              <h3 className={styles.titleSecond}>
                Функція 'документаційний супровід'
              </h3>
              <h4 className={styles.subtitleSecond}>Розглянемо детальніше:</h4>
              <div className={styles.textContainer}>
                <p className={classNames(styles.additional)}>
                  <span>Документаційний супровід</span> виконується нашою
                  людиною замість вас. Вона замовляється додатково, і вартість
                  її вираховується менеджером окремо. <br /> <br />
                  Чи означає це що нам її одразу погодять? - Гарантій немає! У
                  Івано-Франківську діють вимоги до зовнішньої реклами, які саме
                  наш працівник частково знає, проте вони досить часто
                  оновлюються.
                </p>
                <div className={styles.animationContainer}>
                  {/* <Lottie animationData={worker} /> */}
                  <DotLottiePlayer src={worker} autoplay loop />
                </div>
              </div>
              <h5 className={styles.serviceTitle}>Особливості послуги</h5>
              <div className={styles.circleContainer}>
                <div className={styles.circle}>1</div>
                <p className={styles.description}>
                  Cума за таку послугу включає в себе три подачі документів з
                  врахуванням ціни друку! Тобто, перша подача згідно ваших
                  побажань, якщо все ок - то це ок, беремо погодження і ставимо
                  в рамочку на стіну! Але як ні, то приходить офіційна відмова
                  (приблиз. за 15-20 днів) відповідно до якої ми переробляємо і
                  переподаємо наново документи в ЦНАП! І так двічі! На цьому
                  все!
                </p>
              </div>
              <div className={styles.circleContainer}>
                <div className={styles.circle}>2</div>
                <p className={styles.description}>
                  Час - це гроші, тому далі повторні переподачі платні, але як
                  показує практика, не рідко вдається і без третьої подачі
                  обійтись, тут залежить від вашого бажання підлаштовуватись та
                  переробляти рекламу.
                </p>
              </div>
              <div className={styles.circleContainer}>
                <div className={styles.circle}>3</div>
                <p className={styles.description}>
                  Працюємо як ФОП на загальній формі (рахунок включатиме в себе
                  податковий збір, тобто, ПДВ)
                </p>
              </div>
              <div className={styles.circleContainer}>
                <div className={styles.circle}>4</div>
                <p className={styles.description}>
                  Все офіційно, прозоро та чесно! Жодних взяток ми не беремо і
                  не даємо!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.imgContainerTwo}>
          <img src={bg} alt='block background image' />
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
});

export default PassportPage;
