import React from 'react';
import styles from './buttons.module.scss';
import { HashLink } from 'react-router-hash-link';

const ButtonsServices = ({showPage, setShowPage }) => {
  return (
    <div className={styles.buttons}>
      <HashLink
        to='/services/#top'
        smooth
        className={styles.pageButton}
        onClick={() => setShowPage(1)}
      >
        1
      </HashLink>
      <HashLink
        smooth
        to='/services/#top'
        className={styles.pageButton}
        onClick={() => setShowPage(2)}
      >
        2
      </HashLink>
    </div>
  );
};

export default ButtonsServices;


